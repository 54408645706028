.invoice-summary-container {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 25px;
    margin-top: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.invoice-details {
    width: 100%;
}

.order-info-invoice {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
}

.order-info {
    display: flex;
    justify-content: space-between;
}

.order-info p {
    margin-bottom: 5px;
    font-size: 2em;
}
.product-detail{
    background-color: #f8f8f8;
    padding: 10px;
}

.invoice-details-name-container {
    width: 100%;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.invoice-details-name {
    font-weight: bold;
    font-size: 1.5em;
    margin: 0;
}

.invoice-details-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e0e0e0;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.invoice-details-product-quantity,
.invoice-details-product-box-price {
    font-size: 1.25em;
    margin: 0;
}

.invoice-total-shipping {
    font-weight: bold;
    font-size: 1.5em;
    text-align: right;
    margin-top: 10px;
    margin-bottom: 10px;
}

.invoice-total-details-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #d0d0d0;
    padding: 15px;
    border-radius: 5px;
    margin-top: 10px;
}

.invoice-total-label {
    font-weight: bold;
    font-size: 1.5em;
    margin: 0;
}

.invoice-total-amount {
    font-weight: bold;
    font-size: 2.5em;
    color: #000;
    margin: 0;
}

