.location {
    width: 15%;
    max-width: 350px;
    min-width: 200px;
    height: fit-content;
    border: 2px solid #58585a;
    position: relative;
    background-color: #eceded;
    border-radius: 10px;
    margin-top: 1%;
    margin-left: 2%;
    font-family: Montserrat, sans-serif;
    color: #58585a;
}

.location_header {
    text-align: center;
    padding: 5px;
    margin: 0;
    border-radius: 6px 6px 0 0;
    background-color: #ffffff;
    font-size: 12px;
    height: fit-content;
    border-bottom: 1px solid #58585a;
}

.dark_grey_strip {
    width: 100%;
    background-color: #58585a;
    height: 6px;
}

.location_header_text {
    text-align: center;
    padding: 5px;
    margin: 0;
    border-radius: 6px;
    background-color: #ffffff;
    font-size: 12px;
}

.location_info_text {
    text-align: right;
    margin: 0;
    font-size: 12px;
}
