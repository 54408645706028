.unlinked-accounts {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
}

.account-match {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
}

.account-info, .select-container {
    display: flex;
    flex-direction: column;
}
.select-organization-container {
    display: flex;
    min-width: 200px;
    flex-direction: column;
}

.select-client-container {
    display: flex;
    min-width: 200px;
    flex-direction: column;
}

.account-label, .select-label {
    font-weight: bold;
    margin-bottom: 5px;
}

.account-number {
    padding: 10px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.account-select {
    font-size: 16px;
}

.Select__control {
    border-radius: 4px;
    border: 1px solid #ccc;
    height: 40px;
}

.Select__menu {
    border-radius: 4px;
}

.Select__option--is-selected {
    background-color: #007bff;
    color: white;
}
