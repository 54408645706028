.quest-account-row {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    background-color: #f9f9f9;
    border-bottom: 2px solid #eaeaea;
    color: #2c3e50;
    font-size: 15px;
    padding: 10px 20px;
}

.quest-account-row.is-draft {
    background-color: #FAD4C0; /* A soft pastel orange */
}

.quest-account-row.is-not-draft {
    background-color: #8ED2C9; /* A gentle pastel teal */
}


/* Wrapper for the dropdown to ensure it occupies its own line */
.dropdown-wrapper {
    width: 100%; /* Ensure dropdown takes the full width */
    margin-bottom: 10px; /* Add some space below the dropdown */
}

/* Styling for the details wrapper to keep items on the same row */
.details-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between; /* Space out elements evenly */
}

.client-details {
    margin-right: 10px; /* Ensure some space between the client details */
}

/* Styles for custom select dropdown to fit well within the layout */
.custom-select {
    width: 100%; /* Dropdown to occupy full width of its container */
}

.react-select__control {
    border: 1px solid #ccc;
    border-radius: 20px;
    box-shadow: none;
}

.react-select__menu {
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}


/*put client-select-dropdown on its own row0*/
.client-select-dropdown {
    position: relative;
    min-width: 500px; /* Adjusted width for better fit within the layout */
    z-index: 1000;
}


/* Ensures details are spaced out and easily readable */
.organization-details, .client-details {
    display: flex; /* Use flexbox for layout */
    flex-direction: row; /* Stack details vertically */
    gap: 5px; /* Add some space between each detail for better separation */
    font-size: 14px;
}
.custom-select__menu-list {
    z-index: 5000;
}
