/* src/components/product_prices/ProductPricesModal.css */

.product-prices-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);  /* Dimmed background to emphasize the modal */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
}

.product-prices-modal {
    background: #fff;
    border-radius: 12px;
    padding: 30px;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    animation: slideIn 0.4s ease-in-out;
    max-height: 90%;
    overflow-y: auto;  /* Scroll if the content exceeds the modal height */
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.product-prices-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #e0e0e0;
}

.product-prices-modal-header .close-button {
    background: transparent;
    border: none;
    cursor: pointer;
}

.product-prices-modal-content {
    margin-top: 20px;
}

.product-prices-header {
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
    font-size: 1.4rem;
}

.product-prices-grid {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
}

.product-prices-save-action {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.product-prices-save-action .save-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.product-prices-save-action .save-button:hover {
    background-color: #0056b3;
}

/* Additional styling for the form and input fields to make it more visually appealing */
.product-prices-modal input[type="number"] {
    width: calc(100% - 12px);
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease-in-out;
}

.product-prices-modal input[type="number"]:focus {
    border-color: #007bff;
    outline: none;
}
