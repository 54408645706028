.pick-list-item {
    background: #FFF;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center-align the content */
    text-align: center; /* Center-align the text */
    opacity: 100%;
}

.item-quantity {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.product-prefix {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.product-panel {
    font-size: 1em;
    color: #777;
}
