.speed-product-details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 40px 20px;
    margin: 0 auto;
    flex-wrap: wrap;
}

.product-info {
    flex: 1;
    padding-right: 40px;
    max-width: 50%;
}