.panels-page-container {
    width: 100%;
    min-height: 100vh; /* Ensure the height adjusts properly */
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column; /* Stack header, body, and footer vertically */
}

.panels-header {
    width: 100%;
    padding: 10px 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.panels-header img {
    height: 50px;
}

.panels-body-container {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-grow: 1; /* Make the body take up the remaining space */
}

.panels-content-container {
    width: 100%;
}
.filters-container {
    width: 100%;
    background-color: #ffffff; /* White background for contrast */
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for separation */
    padding: 15px 20px; /* Padding for comfortable spacing */
    border-radius: 8px; /* Rounded corners */
    margin-bottom: 20px; /* Space below the filters */
    z-index: 10; /* Ensure it stays above other elements */
    position: relative; /* Default positioning */
}

/* Make the filters sticky when scrolling */
@media (min-width: 768px) {
    .filters-container {
        position: sticky;
        top: 0; /* Stick to the top of the body container */
        z-index: 100; /* Higher than the body content */
    }
}

.panels-table-container {
    width: 100%;
    height: 100%;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
    .panels-body-container {
        padding: 15px;
    }

    .panels-header img {
        height: 40px; /* Reduce header logo size */
    }
}
