.client-notification {
    background-color: #f2f2f2; /* Light background for the notification */
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.client-notification-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.client-notification-content-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.client-notification-content-description {
    font-size: 14px;
    color: #666; /* Darker text for contrast */
}
