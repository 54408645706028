/* Masonry Grid */
.speed-swabs-compare-masonry-grid {
    display: flex;
    margin-left: -15px; /* Reduced gutter size */
}

.speed-swabs-compare-masonry-grid_column {
    padding-left: 15px; /* Reduced padding */
    background-clip: padding-box;
}

/* Product card styling */
.speed-swabs-compare-product-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 10px; /* Smaller padding */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    transition: transform 0.3s ease;
    min-height: 240px; /* Reduced min-height for smaller cards */
}

.speed-swabs-compare-product-card:hover {
    transform: scale(1.03); /* Slight zoom on hover */
}

/* Image container */
.speed-swabs-compare-product-images {
    width: 80%; /* Reduced image size */
    height: auto;
    position: relative;
    margin-bottom: 10px;
}

.speed-swabs-compare-product-image {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease;
}

/* Product title and description */
.speed-swabs-compare-product-name {
    font-size: 1rem; /* Reduced font size */
    font-weight: bold;
    margin: 5px 0;
    text-align: center;
}

.speed-swabs-compare-product-description {
    font-size: 0.8rem; /* Smaller description font size */
    color: #666;
    text-align: center;
    margin-bottom: 5px;
}

/* Drug name and cutoff container */
.speed-swabs-compare-product-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px; /* Reduced gap */
    margin: 5px 0;
    width: 100%;
}

.speed-swabs-compare-option {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 10px; /* Smaller radius */
    font-size: 0.75rem; /* Reduced font size */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
}

.speed-swabs-compare-option-label {
    background-color: #0073e6;
    color: white;
    font-weight: bold;
    padding: 3px 6px; /* Reduced padding */
    border-radius: 10px;
    margin-right: 3px;
}

.speed-swabs-compare-option-value {
    background-color: #f0f4fa;
    color: #333;
    padding: 3px 6px;
    border-radius: 10px;
    font-size: 0.75rem; /* Reduced font size */
    font-weight: 500;
}

/* Add to cart button */
.speed-swabs-compare-add-to-cart-button {
    background-color: #00cc66;
    color: white;
    border: none;
    padding: 5px 10px; /* Smaller padding */
    border-radius: 30px; /* Smaller border-radius */
    font-size: 0.85rem; /* Reduced font size */
    cursor: pointer;
    margin-top: 5px;
    width: 40%; /* Reduced button width */
}

.speed-swabs-compare-add-to-cart-button:hover {
    background-color: #00a855;
}
