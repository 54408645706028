.speed-product-guarantee {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
    background-color: #f9f9f9;
    margin-top: 20px;
}

.guarantee-item {
    text-align: center;
    flex: 1;
    padding: 0 10px;
}

.guarantee-item img {
    max-width: 50px;
    margin-bottom: 10px;
}

.guarantee-item p {
    font-size: 0.9rem;
    margin: 0;
}
