.fusion-footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    width: 100%;
}

.fusion-footer p {
    margin: 0;
    font-size: 14px;
}
