/* Header Row Container */
.client-screens-header-row {
    display: grid;
    grid-template-columns: repeat(10, 1fr); /* Adjust for the number of columns */
    gap: 1rem;
    background-color: #3e3e3e;
    color: #f9f9f9;
    padding: 1rem;
    border-radius: 8px;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
}

/* Header Cells */
.client-screens-header-cell {
    font-size: 0.9rem;
    text-transform: uppercase;
    overflow-wrap: break-word;
}
