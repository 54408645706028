.messages-modal {
    position: absolute;
    top: 60px;
    left: 0;
    width: 300px;
    height: calc(15vh - 5px);
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 0 15px 15px 0;
    overflow-y: auto;
    z-index: 8000;
    border: 1px solid #e0e0e0;
}

.messages-modal-header {
    padding: 15px;
    background-color: #f8f8f8;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    border-top-right-radius: 14px;
    position: relative;
}

.messages-modal-exit-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    font-weight: bold;
}

.messages-modal-content {
    padding: 15px;
}
