.automation-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #ffffff;  /* Added a light background color */
}

.content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    max-width: 1600px;
    margin: 0 auto;
}

.labb-clients-search-header {
    margin-bottom: 20px;

    margin-top: 20px;
}
.submit-initiate-automation-button {
    margin-bottom: 20px;
    margin-top: 20px;
}
