.read-tile-main-body{

    min-width: 180px;
    max-width: 180px;
    height: 65px;
    margin-left: 15px;
    margin-top: 11px;
    margin-bottm: 11px;
    border-radius: 3px;
    box-shadow: 0px 3px 6px #00000029;
    overflow-y: auto; /* add scrolling */
    -ms-overflow-style: none; /* Internet Explorer 10+ remove scroll bar */
    scrollbar-width: none; /* Firefox remove scroll bar */
}

.read-tile-text-frame{
    font-size: 12px;
    padding: 10px;
    margin-top: 0;
}

.read-tile-scan-info-text{
    font-size: 12px;
    text-align: left;
    margin-bottom: 0;
    margin-top: 5px;
}
.read-tile-info-frame{
    color: #58585A;
}
