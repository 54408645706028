/* Login.css */

.login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f9fc;
}

.login-card {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    width: 350px;
    text-align: center;
}

.login-logo {
    width: 120px;
    margin-bottom: 30px;
}

.login-form .login-btn {
    width: 100%;
    padding: 12px 15px;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-form .login-btn:hover {
    background-color: #0056b3;
}
