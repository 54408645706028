
/* ProductPickList.css */
.product-pick-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.pick-list-item {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px;
    text-align: center;
    height: fit-content;
    opacity: 100%;
}

.pick-list-item.selected {
    background-color: #D9D9D9;
}