.screens-page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f0f2f5;
}

.screens-header-container {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
}

.screens-body-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding: 20px;
}

.screens-content-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    overflow: visible;
}

@media (max-width: 768px) {
    .screens-content-container {
        padding: 10px;
    }
}
