.date-range-picker-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
    z-index: 20;
}

.date-range-picker-container .cell {
    flex: 1 1 100%;
}

.filter-component {
    flex: 1;
    min-width: 200px;
}

.date-range-picker-container .rs-picker {
    width: 100%;
}
