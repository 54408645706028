/* PricesRow.css */
.price-row {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    margin: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 350px;
}

.price-row-service-code{
    background: #6f7170;
    color: white;
    padding: 10px;
    width: 100px;
    border-radius: 10px;
}

.price-row-header,
.price-row-body,
.price-row-costs,
.price-row-footer {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.price-row-header {
    background-color: #f9f9f9; /* Light gray background for header */
    border-bottom: 1px solid #eee; /* Separator line */
}

.price-row-header h2 {
    font-size: 16px;
    margin: 0;
}


.price-row-body {
    flex-grow: 1; /* Allow body to grow and fill space */
}

.price-row-costs {
    background-color: #f9f9f9; /* Light gray background for costs */
    border-top: 1px solid #eee; /* Separator line */
}

/* You can specify a fixed height for the rows if needed */
.price-row {
    height: 180px; /* Adjust as needed */
}
