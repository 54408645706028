.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    max-height: 80vh;
}

.modal-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.selected-client-info{
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
}

.order-detail-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.order-detail-column {
    flex: 1;
    min-width: 300px;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    background: #f9f9f9;
}

@media (max-width: 768px) {
    .order-detail-section {
        flex-direction: column;
    }
}
