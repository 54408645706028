.screens-container {
    overflow-y: auto;
    max-height: calc(100vh - 50px);
}

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

thead th {
    background-color: #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 500;
}

th, td {
    padding: 10px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
}

th {
    font-weight: bold;
}

tr:nth-child(odd) {
    background-color: #f9f9f9;
}

tr:nth-child(even) {
    background-color: #ffffff;
}

.scroll-container {
    display: block;
    overflow-y: auto;
    max-height: calc(100vh - 50px); /* Adjusted for header height */
}


