:root {
    --main-bg-color: #f5f5f5;
    --text-color: #333;
    --header-bg-color: #ffffff;
    --border-color: #e1e1e1;
    --hover-bg-color: #e9ecef;
    --font-family: 'Arial', sans-serif;
    --accent-color: #007bff;
    --hover-accent-color: #0056b3;
    --modal-header-font-size: 18px;
}

.quest-account-modal {
    background-color: var(--main-bg-color);
    color: var(--text-color);
    font-family: var(--font-family);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 90vw;
    margin: auto;
    overflow: visible; /* Ensure modal itself doesn't clip content */
}

.quest-account-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--header-bg-color);
    padding: 10px 15px;
    border-bottom: 1px solid var(--border-color);
    font-size: var(--modal-header-font-size);
}

.quest-account-container {
    margin-top: 10px;
    overflow-y: auto; /* Only vertical scroll inside the container */
}



/* Adjusting table layout for responsiveness and clear structure */
table#tableQuestionAccount {
    width: 100%;
    border-collapse: collapse;
}

table#tableQuestionAccount .custom-client-search-dropdown {
    width: 100%; /* Ensure dropdown fits within its cell */
}

table#tableQuestionAccount .dropdown-search-input,
table#tableQuestionAccount .dropdown-list {
    position: absolute; /* Allow dropdown content to extend outside */
    z-index: 30; /* Ensure dropdown is above all other content */
}

table#tableQuestionAccount tr {
    position: relative; /* Needed for proper z-index stacking context */
}

/* Fix for dropdown being cut off */
table#tableQuestionAccount,
.quest-account-modal,
.quest-account-container {
    overflow: visible; /* Allow dropdown to extend outside */
}

/* Additional adjustments for visual consistency and layout */
table#tableQuestionAccount th,
table#tableQuestionAccount td {
    padding: 15px;
    border-bottom: 1px solid var(--border-color);
}

/* Ensure headers have a consistent appearance */
table#tableQuestionAccount th {
    background-color: var(--header-bg-color);
    font-weight: bold;
}

/* Adjusting action buttons layout */
.actionButtons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

/* Style adjustments for inner components and fake table layout */
.quest-account-row-inner, .header-fake-inner-row {
    display: flex;
    align-items: center;
    width: 100%;
}

.header-fake-inner-row span,
span.cell-fake-table-data {
    flex-grow: 1; /* Allow flexible distribution of space */
    padding: 10px; /* Consistent padding */
    text-align: left; /* Align text to the left for readability */
}

/* Specific widths can be adjusted to fit your layout needs */
.header-fake-inner-row span:first-child,
span.cell-fake-table-data:nth-child(1) {
    flex-basis: 10%; /* Adjust based on content */
}

/* Dropdown adjustments for visual hierarchy and interaction */
.quest-account-row-inner .dropdown-list.show {
    width: 100%; /* Ensure dropdown uses the full width available */
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Soft shadow for dropdown list */
}
