/* General page container */
.client-screens-page-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
}

/* Header styles */
.client-screens-header-container {
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    z-index: 1000;
}

/* Body container */
.client-screens-body-container {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
}

/* Filters section container */
.client-screens-filter-content-container {
    width: 100%;
    max-width: 1200px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
}

/* Filters group container */
.client-screen-filters-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px; /* Space between filters */
    align-items: center;
    justify-content: space-between;
}

.client-screen-filters-container .react-select__control,
.client-screen-filters-container .rs-picker {
    flex: 1 1 150px; /* Flex-grow, flex-shrink, and minimum width */
    max-width: 200px; /* Prevent filters from growing too large */
    min-width: 150px; /* Ensure filters maintain a minimum size */
}


/* Content section container */
.client-screens-content-container {
    width: 100%;
    max-width: 1200px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
}

/* Loading or empty state styles */
.client-screens-content-container p {
    text-align: center;
    color: #666;
    font-size: 1rem;
}

/* Responsive design */
@media (max-width: 768px) {
    .client-screens-filter-content-container {
        padding: 15px;
    }

    .client-screen-filters-container {
        gap: 10px;
        justify-content: flex-start; /* Align filters to the start on smaller screens */
    }
    .filter-group {
        flex-direction: column;
        align-items: stretch;
    }

    .filter-group .react-select__control,
    .filter-group .rs-picker {
        width: 100%;
    }

}
