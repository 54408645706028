.navbar-container {
    /* Styles for the navbar container */
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #f0f0f0; /* Example background color */
    padding: 10px;
}

.navbar-container div {
    /* Styles for each navbar item */
    cursor: pointer;
}

.selectedIconTab {
    /* Styles for the selected/active icon */
    color: #007bff; /* Example active color - blue */
    border-bottom: 2px solid #007bff; /* Example bottom border */
}

/* You might have specific styles for each icon, for instance: */
.icon-specimen, .icon-laboratory, .icon-person, .icon-upload, .icon-bell {
    /* Common styles for all icons */
    fill: #333; /* Default icon color */
    width: 30px; /* Adjust as necessary */
    height: 30px; /* Adjust as necessary */
}

/* When an icon is active/selected */
.selectedIconTab .icon-specimen,
.selectedIconTab .icon-laboratory,
.selectedIconTab .icon-person,
.selectedIconTab .icon-upload,
.selectedIconTab .icon-bell {
    fill: #007bff; /* Active icon color - blue */
}
