/* Overlay that covers the entire screen */
.speed-cart-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    z-index: 11000;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Prevent scrolling within the overlay */
}

/* Main container for the modal */
.speed-cart-modal-container {
    display: flex;
    width: 100%;  /* Adjust width as needed */
    height: 100%;
    position: relative;
    overflow: hidden; /* Prevent overflow outside the modal */
}
/* Left side - Full Blue Background */
.speed-cart-left-full {
    background: #007bff;
    width: 100%;
    display: flex;
    height: 100%; /* Set height to full screen */
}

/* Inner section to center content and take up 50% */
.speed-cart-left-inner {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center; /* This centers the content vertically and horizontally */
    flex-direction: column; /* Stack the content vertically */
}

/* Branding text on the left side */
.speed-cart-branding {
    text-align: center; /* Ensure the text is centered */
    color: white;
}

.speed-cart-branding h1 {
    font-size: 2.5rem;
    margin-bottom: 5px;
}

.powered-by-labb {
    font-size: 1.2rem;
    text-align: right; /* Right align 'powered by Labb' */
    color: white;
}

/* Right side that overlaps slightly and has rounded corners */
.speed-cart-right-section {
    background: white;
    width: 50%;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px;
    box-shadow: -4px 0 15px rgba(0, 0, 0, 0.1);
    z-index: 1;  /* Ensure this section is on top */
    /*margin-left: -5%;*/
}

/* Header with 'Your Cart' text and close button */
.speed-cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.speed-cart-close-button {
    position: absolute;
    top: 20px;
    right: 20px;
}

/* Cart items section */
.speed-cart-items-section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

/* Add styles for the upsell section and footer */

.speed-cart-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
