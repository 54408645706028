.freddy-public-user-management-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.invite-section {
    background-color: #f7f7f7;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.invite-section h2 {
    margin-bottom: 15px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.user-list-section {
    margin-top: 20px;
}

.user-list-section h2 {
    margin-bottom: 10px;
}

.user-list-section ul {
    list-style-type: none;
    padding-left: 0;
}

.user-list-section li {
    padding: 10px;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
