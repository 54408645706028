.loading-table-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-table-image-container {
    max-width: 100%;
    max-height: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.loading-table-image {
    max-width: 100%;
    max-height: 100%;
}
