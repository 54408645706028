/* freddy-react/src/components/clients/clients_row/ClientsRow.css */
.client-row {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    align-items: center;
}

.client-cell {
    padding: 5px 10px;
}

/* Client company cell now uses dark grey for the name */
.client-company-cell {
    flex: 2;
    font-size: 16px;
    font-weight: bold;
    color: #4a4a4a;   /* Dark grey */
    text-align: left;
}
/* Client company cell now uses dark grey for the name */
.client-name {
    flex: 2;
    font-size: 16px;
    font-weight: bold;
    color: #4a4a4a;   /* Dark grey */
    text-align: left;
}

.client-address-cell {
    flex: 2;
    font-size: 12px;
    color: #666;
    text-align: left;
}
.client-address {
    flex: 2;
    font-size: 12px;
    color: #666;
    text-align: left;
}

.client-services-cell {
    flex: 1.85;
    text-align: center;
}

.service-group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
}

.pill {
    background-color: #f0f0f0;
    border-radius: 15px;
    padding: 3px 8px;
    font-size: 0.85em;
    white-space: nowrap;
}

.client-additional-cell {
    flex: 1;
    text-align: center;
}

.client-organization-string-cell {
    flex: 1;
    text-align: center;
}

.client-positive-string-cell {
    flex: 1;
    text-align: center;
}

.client-action-cell {
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.check-icon {
    width: 16px;
    height: 16px;
}

.client-expand-cell {
    display: flex;
    align-items: center;
    justify-content: center;
}

.client-expanded-row {
    background-color: #f9f9f9;
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
}

.client-expanded-content {
    padding: 15px 0;
}
