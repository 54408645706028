.do-not-overpay-section {
    margin: 60px 0; /* Significant margin on top and bottom for spacing */
    padding-right: 20%; /* Move the entire content further to the right */
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align all content to the right */
    text-align: right; /* Right-align text */
}

.do-not-overpay-heading-container {
    max-width: 900px;
    margin-bottom: 20px;
}

.do-not-overpay-heading {
    font-size: 3rem;
    font-weight: bold;
    color: #706F70;
}

.highlight {
    color: #ff4747; /* Highlighted text color */
}

.do-not-overpay-description-container {
    max-width: 550px;
    margin-bottom: 30px;
}

.do-not-overpay-description {
    font-size: 1.5rem; /* Adjusted font size for consistency */
    color: #706F70;
    margin: 0;
}

.do-not-overpay-buttons {
    display: flex;
    justify-content: flex-end; /* Right-align button */
}

.primary-button {
    width: 200px; /* Fixed width for the primary button */
    padding: 10px 20px; /* Padding to ensure proper height */
}
