.countdown-timer {
    height: 5px;
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 2px;
    overflow: hidden;
}

.countdown-progress {
    height: 100%;
    background-color: #3C74B9;
    transition: width 1s linear;
}