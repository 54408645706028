/* freddy-react/src/components/clients/ClientsTable.css */
.client-table-container {
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 100vh;             /* Full viewport height */
    display: flex;
    flex-direction: column;
}

.client-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.client-search-container {
    flex: 1;
}

.client-search-input {
    width: 100%;
    padding: 8px 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
}

.account-number-search-container {
    flex: 1;
}

.account-number-search-input {
    width: 100%;
    padding: 8px 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
}

.client-button-container {
    display: flex;
    gap: 10px;
}

.client-primary-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 14px;
}

.client-primary-button:hover {
    background-color: #0056b3;
}

.client-primary-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.client-icon-button {
    background: none;
    border: none;
    cursor: pointer;
}

.client-table-content {
    flex: 1;                      /* Fills the remaining height */
    overflow-x: auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.client-table {
    width: 100%;
}

.client-table-header,
.client-table-body .client-row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 12px 15px;
    background-color: #f2f2f2;
}

.client-table-header {
    font-weight: bold;
}

/* Column classes with adjusted flex values and fixed widths for the Boolean columns */
.client-col-client { flex: 2; }
.client-col-rapid { flex: 1.5; }
.client-col-tox { flex: 1.5; }
.client-col-dot { flex: 0 0 50px; text-align: center; }
.client-col-paper { flex: 0 0 50px; text-align: center; }
.client-col-org { flex: 1; text-align: center; }
.client-col-positive { flex: 1; text-align: center; }
.client-col-actions { flex: 1; text-align: center; }

.client-table-body {
    max-height: calc(100% - 70px); /* Adjust header height as needed */
    overflow-y: auto;
}
