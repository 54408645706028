/* PasteUpload.css */

.paste-upload-modal {
    background-color: #484848;
    border-radius: 10px;
    padding: 10px;
    color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.modal-header {
    display: flex;
    justify-content: flex-start;
}

.close-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.paste-upload-text,
.paste-upload-instruction {
    background-color: #f8f8f8;
    border-radius: 10px;
    padding: 10px;
    color: #484848;
    margin-top: 5px;
    overflow-wrap: break-word;
}

.paste-upload-text {
    font-weight: bold;
}
