
/* ProductImages.css */
.product-images {
    flex: 1;
    display: flex;
    align-items: flex-end;
    margin: 25% auto 0;
    position: relative;
    width: 50%;
    height: 300px; /* Reduced height to make images shorter */
    justify-content: center;
}

.product-variant-image {
    cursor: pointer;
    width: 110px;
    height: auto;
    object-fit: contain;
    border: 2px solid transparent;
    transition: transform 0.5s ease, border 0.3s ease;
    transform-origin: bottom center;
    position: absolute;
    bottom: 0;
}

.product-variant-image:nth-child(1) {
    transform: rotate(-15deg);
    left: 250px;
}

.product-variant-image:nth-child(2) {
    transform: rotate(-10deg);
    left: 255px;
}

.product-variant-image:nth-child(3) {
    transform: rotate(-5deg);
    left: 280px;
}

.product-variant-image:nth-child(4) {
    transform: rotate(5deg);
    left: 285px;
}

.product-variant-image:nth-child(5) {
    transform: rotate(15deg);
    left: 295px;
}

.product-variant-image.selected {
    z-index: 2;
    transform: var(--rotate) scale(1.1);
}

.product-variant-image:hover {
    z-index: 2;
    transform:  var(--rotate) scale(1.05);
}

/* Animation for fanning out */
@keyframes fanOut {
    0% {
        transform: rotate(0deg) translateY(0);
    }
    100% {
        transform: var(--rotate) translateY(0);
    }
}

.product-variant-image {
    animation: fanOut 0.8s ease;
    --rotate: rotate(0deg);
}

.product-variant-image:nth-child(1) {
    --rotate: rotate(-15deg);
}

.product-variant-image:nth-child(2) {
    --rotate: rotate(-10deg);
}

.product-variant-image:nth-child(3) {
    --rotate: rotate(-5deg);
}

.product-variant-image:nth-child(4) {
    --rotate: rotate(5deg);
}

.product-variant-image:nth-child(5) {
    --rotate: rotate(15deg);
}