.specimen-notes-container {
    max-height: 75px;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.note-input-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.note-input-container input {
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.no-notes {
    text-align: center;
    color: #888;
    font-size: 14px;
    margin-top: 20px;
}

.specimen-note {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
