/* Container for the total details section */
.cart-total-details {
    background-color: #707070;
    padding: 20px;
    border-radius: 0 100px 100px 0; /* Rounded only on the right side */
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-left: -40px;
    margin-top: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Drop shadow */
}

/* Total header, aligned to the left */
.cart-total-header {
    display: flex;
    justify-content: space-between; /* Space between for title and price */
    width: 100%;
    align-items: center;
    margin-left: 40px;
}

/* Estimated Total Text */
.cart-total-header h2 {
    font-size: 1.5rem;
    color: #fff; /* White text */
    margin: 0;
}

/* Price circle */
.cart-total-price {
    background-color: #007bff;
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
    border-radius: 50%;
    width: 130px;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
}

/* Disclaimer for shipping and taxes */
.cart-disclaimer {
    font-size: 0.875rem;
    color: #fff; /* White text for disclaimer */
    margin-top: 10px;
    text-align: left;
    margin-left: 40px;
}
