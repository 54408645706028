.shipment-box-details {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin-bottom: 20px;
}

.box-detail {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
}

.box-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.box-image-large {
    width: 100px;
    height: 100px;
}

.box-details {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.box-product-name {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
}

.box-quantity {
    font-size: 14px;
    margin-bottom: 5px;
}

.box-subdetails {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 10px;
}

.box-subdetails div {
    flex: 1;
    text-align: center;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f1f1f1;
    margin-right: 5px;
}

.box-subdetails div:last-child {
    margin-right: 0;
}
