.speed-product-details .product-info .product-features {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 16px;
    font-weight: 500;
    color: #706F70;
    margin: 20px 0;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.speed-product-details .product-info .product-features div {
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.speed-product-details .product-info .product-features div:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
