.ordered-product-card {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the items horizontally */
    justify-content: start; /* Aligns items to the top */
    width: 100%;
    padding: 16px;
    background-color: #D9D9D9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
}

.product-name-container {
    margin-bottom: 12px;
    justify-content: center;
}

.product-name {
    font-size: 1.2em;
    font-weight: bold;
    justify-content: center;
    margin: 0;
    color: #333;
}

.product-quantity-container {
    background-color: #e0e0e0;
    padding: 6px 12px;
    border-radius: 4px;
    margin-bottom: 5px;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.product-quantity {
    font-size: calc(5vw + 1em); /* Dynamically adjust based on viewport width */
    font-weight: bold;
    color: #333;
    text-align: center;
    line-height: 1; /* Ensures no extra line height is added */
    margin: 0 0 5px 0; /* Adjust margins as needed */
}

.product-box-breakdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
    width: 100%;
}

.box-types {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
    background-color: #f8f9fa;
    border-radius: 4px;
}

.box-indicator {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-indicator .count {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 25px;
}

.boxes-of-4 {
    background-color: #4CAF50;
}

.boxes-of-2 {
    background-color: #FDD835;
}

.single-boxes {
    background-color: #F44336;
}
