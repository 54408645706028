/* Labb Dashboard specific styles */
.labb-dashboard-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9fc; /* Slightly softer background */
    min-height: 100vh; /* Ensure full vertical space */
    padding: 20px;
    box-sizing: border-box;
}

.labb-dashboard-content {
    background: #ffffff;
    padding: 40px 35px;
    border-radius: 12px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
    transition: padding 0.3s ease, box-shadow 0.3s ease;
}

/* Responsive typography */
.dashboard-title {
    font-size: 1.8rem;
    font-weight: 700;
    color: #222;
    margin-bottom: 15px;
    line-height: 1.4;
}

.dashboard-message {
    font-size: 1rem;
    color: #555;
    margin-bottom: 25px;
    line-height: 1.5;
}

/* Button container */
.login-button-container {
    display: flex;
    justify-content: center;
}

.login-button-container .PrimaryButton {
    font-size: 1rem;
    padding: 14px 20px;
    width: 100%;
    max-width: 100%; /* Prevent button from exceeding container on small screens */
    background-color: #0073e6;
    color: white;
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    transition: background-color 0.3s ease, transform 0.2s ease;
    cursor: pointer;
}

.login-button-container .PrimaryButton:hover {
    background-color: #005bb5;
    transform: translateY(-2px);
}

.login-button-container .PrimaryButton:focus-visible {
    outline: 3px solid #80bfff; /* Focus style for accessibility */
    outline-offset: 2px;
}

/* Adjustments for larger screens */
@media (min-width: 768px) {
    .labb-dashboard-content {
        padding: 50px 45px;
        max-width: 500px;
    }

    .dashboard-title {
        font-size: 2rem;
    }

    .dashboard-message {
        font-size: 1.1rem;
    }

    .login-button-container .PrimaryButton {
        font-size: 1.125rem;
    }
}

/* Styling for smaller devices */
@media (max-width: 768px) {
    .labb-dashboard-content {
        padding: 30px 25px;
    }

    .dashboard-title {
        font-size: 1.6rem;
    }

    .dashboard-message {
        font-size: 0.95rem;
    }

    .login-button-container .PrimaryButton {
        font-size: 0.95rem;
    }
}

@media (max-width: 576px) {
    .labb-dashboard-content {
        padding: 25px 20px;
    }

    .dashboard-title {
        font-size: 1.4rem;
    }

    .dashboard-message {
        font-size: 0.9rem;
    }

    .login-button-container .PrimaryButton {
        font-size: 0.85rem;
        padding: 12px 16px;
    }
}