
.clients-content-container {
    position: relative;
    z-index: 1;
}

.labb-clients-search-header .dropdown-list.show {
    position: absolute;
    z-index: 999;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}