/* Main Panel Container */
.panels-container {
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
}

/* Filters and Legend */
.filter-and-legend {
    display: flex;
    width: 100%;
    gap: 20px;
}

/* Filters Section */
.filter-section {
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

/* Input Row for Panel Code and Select Drugs */
/* Input Row for Panel Code and Select Drugs */
.input-row {
    display: flex;
    gap: 20px;
    align-items: center;
    width: 100%;
}


/* Input Fields */
.panel-select {
    flex: 1;
    padding: 10px 14px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: #ffffff;
    outline: none;
    transition: border-color 0.2s ease;
}

.panel-select:focus {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.3);
}

/* Panel Count Buttons and Filters */
.panel-counts-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.panel-count-button {
    padding: 10px;
    font-size: 14px;
    background-color: #ffffff;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s;
}

.panel-count-button:hover {
    background-color: #e0e0e0;
}

.panel-count-button.selected {
    background-color: #007bff;
    color: #ffffff;
    border-color: #007bff;
}

/* Legend Section */
.panels-legend-section {
    flex: 1;
    background-color: #f8f9fa;
    padding: 16px;
    border-radius: 8px;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.05);
}

/* Selected Filters */
.selected-filters-panels {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.selected-filter-panels {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    background-color: #e9ecef;
    border-radius: 6px;
    font-size: 14px;
}

/* Updated Select Style */
.filter-select-panels {
    flex: 1;
    padding: 10px 14px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: #ffffff;
    outline: none;
    transition: border-color 0.2s ease;
}

.filter-select-panels:focus {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.3);
}


/* Responsive Adjustments */
@media (max-width: 768px) {
    .filter-and-legend {
        flex-direction: column;
    }

    .input-row {
        flex-direction: column;
    }

    .panel-select {
        width: 100%;
    }

    .panel-count-button {
        font-size: 12px;
    }
}
