/* QuestAccountRequestModal.css */

.account-request-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.account-request-modal {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 500px;
    max-width: 90%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative;
}

.account-request-modal-header {
    display: flex;
    justify-content: flex-end;
}

.account-request-modal-title {
    text-align: center;
    margin-top: 0;
    font-size: 1.5em;
    color: #333;
}

.account-details-section {
    margin-top: 15px;
}

.account-details-section h3 {
    margin-bottom: 10px;
    color: #555;
}

.account-details-section div {
    margin-bottom: 8px;
    font-size: 0.95em;
    color: #333;
}

.action-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.action-buttons button {
    width: 100%;
    padding: 10px 15px;
}
