.employee-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    overflow: hidden;
}

.employee-header {
    display: grid;
    grid-template-columns: repeat(18, 1fr); /* Adjust to the number of columns you need */
    gap: 10px;
    padding: 10px;
    background-color: #f5f5f5;
    font-weight: bold;
    border-bottom: 1px solid #e1e1e1;
}

.employee-row {
    display: grid;
    grid-template-columns: repeat(18, 1fr); /* Adjust to match the header */
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #e1e1e1;
}

.employee-row div {
    display: flex;
    flex-direction: column;
}

.employee-row div span {
    font-weight: bold;
    margin-bottom: 5px;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .employee-header,
    .employee-row {
        grid-template-columns: 1fr 1fr; /* Two columns in smaller screens */
    }

    .employee-row div span {
        margin-bottom: 3px;
    }
}

@media (max-width: 768px) {
    .employee-header,
    .employee-row {
        grid-template-columns: 1fr; /* One column in smaller screens */
    }
}
