.client-number-details-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.client-number-details-modal {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 500px;
    padding: 20px;
    position: relative;
    animation: fadeIn 0.3s ease-out;
}

.modal-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
}

.modal-content h2 {
    font-size: 1.4em;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
}

.search-result-item {
    background: #f5f5f5;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.search-result-item h3 {
    font-size: 1.1em;
    color: #555;
    margin-bottom: 5px;
}

.search-result-item p {
    margin: 0;
    color: #666;
    font-size: 0.9em;
}

@keyframes fadeIn {
    from { opacity: 0; transform: scale(0.9); }
    to { opacity: 1; transform: scale(1); }
}
