/* CSS: ProgressBar.css */
.progress-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.progress-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 1;
}

.step-icon-container {
    background-color: #ffffff;
    border: 2px solid #d1d5db;
    border-radius: 50%;
    padding: 10px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.step-icon {
    width: 24px;
    height: 24px;
    transition: filter 0.3s ease;
}

.step-icon.icon-active {
    filter: invert(1);
}

.step-label {
    margin-top: 8px;
    font-size: 0.875rem;
    color: #4a5568;
    text-align: center;
    transition: color 0.3s ease;
}

.step-line {
    position: absolute;
    top: 50%;
    right: -50%;
    width: 100%;
    height: 2px;
    background-color: #d1d5db;
    z-index: -1;
    transition: background-color 0.3s ease;
}

.progress-step.active .step-icon-container {
    background-color: #4a90e2;
    border-color: #4a90e2;
}

.progress-step.active .step-icon {
    filter: invert(1);
}

.progress-step.active .step-line {
    background-color: #4a90e2;
}

.progress-step.active .step-label {
    color: #2d3748;
    font-weight: bold;
}

@media (max-width: 768px) {
    .progress-bar {
        flex-direction: column;
        padding: 10px;
    }

    .progress-step {
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;
    }

    .step-line {
        display: none;
    }

    .step-label {
        margin-left: 10px;
    }
}
