/* Billing.css styles */
.billing-page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f0f2f5;
}

.billing-actions,
.billing-section {
    width: 100%; /* Adjusted for full width */
    margin: 0 auto; /* Centering */
    background-color: #ffffff; /* White background */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    padding: 20px; /* Adjusted padding */
}


/* Ensuring consistency with screens-body-container for any additional body content */
.screens-body-container,
.billing-body-container { /* Assuming you might want to apply similar styling for billing body */
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding: 20px;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .billing-actions,
    .billing-section,
    .screens-content-container {
        padding: 10px; /* Reduced padding on smaller screens */
    }
}