.order-detail-ship-address-options-container {
    margin-bottom: 20px;
}

.filter-select {
    width: 100%;
}

.ship-options-rates-container {
    margin-top: 10px;
}

.ship-options-rates-container div {
    background: #f9f9f9;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
