.note-column-bubble {
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    padding: 10px;
    overflow: hidden;
    max-height: 300px;
    overflow-y: auto;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.note-container {
    padding: 12px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.note-content {
    font-size: 14px;
    color: #333;
    margin-bottom: 8px;
    word-wrap: break-word;
    white-space: normal;
}

.note-meta {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 12px;
    color: #666;
}

.note-meta > div {
    display: flex;
    justify-content: space-between;
}

.note-author {
    font-weight: bold;
    color: #444;
}

.note-date,
.note-client,
.note-location {
    color: #666;
    font-style: italic;
}
