/* General layout for the client orders page */
.client-orders-page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
}

/* Ensure the body container grows to fill the remaining space */
.orders-body-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    background-color: #f0f0f0;
}

/* Content container for orders */
.orders-content-container {
    width: 100%;
    max-width: 1200px; /* Limit content width on large screens */
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: padding 0.3s ease;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
    .orders-body-container {
        padding: 15px; /* Reduce padding on mobile */
    }

    .orders-content-container {
        padding: 15px; /* Reduce padding inside content on mobile */
        max-width: 95%; /* Keep the content at 95% of the screen width */
    }
}

/* Typography */
h1, h2, h3 {
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 1.5rem; /* Adjust font size */
}

p {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #555;
}
