.panel-tile-tiles {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    transition: box-shadow 0.3s ease;
    box-sizing: border-box;
    width: 100%;
}

.panel-tile-tiles:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.panel-header-tiles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    gap: 10px;
}

.panel-header-tiles h3 {
    margin: 0;
    font-size: 1.2em;
    color: #333;
    font-weight: bold;
    cursor: pointer;
    flex-grow: 1;
}

.panel-code-tiles {
    font-size: 1em;
    color: #fff;
    font-weight: bold;
    background: #39B54A;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
}

.panel-detail-tiles {
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 4px;
    border: 1px solid #ddd;
}


.client-price-tiles {
    font-size: 1em;
    font-weight: bold;
    color: #145890; /* Matches the dark blue used in details */
    background: #e6f7ff; /* Light blue background */
    border: 1px solid #145890; /* Adds contrast with the border */
    border-radius: 5px;
    padding: 5px 15px; /* Adds padding for a boxy feel */
    text-align: center;
    margin-left: 10px; /* Space between the code and price */
    white-space: nowrap; /* Prevents wrapping */
}

.component-details-panel-tiles {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    transition: background-color 0.3s ease;
}

.component-details-panel-tiles:hover {
    background-color: #f1f3f5;
}

.component-item-details-tiles {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 5px 0;
    background-color: #145890;
    border-radius: 4px;
    padding: 5px 10px;
    color: #ffffff;
}

.component-item-panel-tiles {
    flex: 1;
    margin-left: 10px;
}

/* Bubble styles for TC Type */
.tc-type-bubble {
    margin-top: 5px;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    color: #fff;
    opacity: 20%;
    transition: opacity 0.3s ease;
    font-weight: bold;
}

/* Color schemes for different TC Types */
.tc-type-adulterant { background-color: #FFD700; } /* Gold */
.tc-type-adulterant-required { background-color: #FF4500; } /* Orange Red */
.tc-type-confirmation-test { background-color: #1E90FF; } /* Dodger Blue */
.tc-type-remark-test { background-color: #8A2BE2; } /* Blue Violet */
.tc-type-other-test { background-color: #32CD32; } /* Lime Green */
.tc-type-quant-result { background-color: #FF1493; } /* Deep Pink */
.tc-type-ram-test { background-color: #FF6347; } /* Tomato */
.tc-type-screening-test { background-color: #00BFFF; } /* Deep Sky Blue */

.no-matching-panel-tiles {
    text-align: center;
    color: #999;
    padding: 10px 0;
}

/* Masonry styles within PanelTile */
.my-masonry-grid {
    display: flex;
    width: 100%;
    margin-left: -30px; /* gutter size offset */
}
.my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}
.my-masonry-grid_column > div {
    background: white;
    margin-bottom: 30px;
}
