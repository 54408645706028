.user-icon-card {
    position: relative;
    display: inline-block;
}

.person-icon-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    position: relative;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hover-icon {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
}
.initials-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
    user-select: none;
}

.dropdown-menu {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    min-width: 150px;
    z-index: 100;
}

.dropdown-menu p {
    margin: 0 0 10px 0;
    font-size: 14px;
    color: #333;
}

.dropdown-menu button {
    background-color: #FF5733;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}

.dropdown-menu button:hover {
    background-color: #C70039;
}
