.specimens-filter-input {
    margin-bottom: 15px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease-in-out;
}

.specimens-filter-input:focus {
    border-color: #007bff; /* Adjust to match your app's primary color */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.2); /* Subtle focus shadow */
}

.mro-specimens-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional box shadow for the container */
}

.scroll-container {
    overflow-y: auto;
    max-height: 90vh;
}

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    word-wrap: break-word; /* Ensures long text wraps in table cells */
}

thead th {
    background-color: #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 10;
}

tr:nth-child(odd) {
    background-color: #f9f9f9;
}

tr:nth-child(even) {
    background-color: #ffffff;
}