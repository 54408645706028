.screener-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    overflow: hidden;
}

.screener-header, .screener-row {
    display: grid;
    grid-template-columns: 40px 1fr 1fr repeat(5, 100px);
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #e1e1e1;
}

.screener-header {
    background-color: #f5f5f5;
    font-weight: bold;
}

.scrollable-email {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: auto;
    max-width: 100%;
    color: #555;
    -ms-overflow-style: none;  /* Internet Explorer 10+ remove scroll bar */
    scrollbar-width: none;  /* Firefox remove scroll bar */
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.scrollable-email::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE and Edge */
.scrollable-email {
    -ms-overflow-style: none;
}

input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
    width: 20px;
    height: 20px;
    cursor: not-allowed; /* Since checkboxes are disabled */
    display: inline-block;
    position: relative;
}

input[type="checkbox"]:checked:before {
    content: '\2713'; /* Unicode checkmark */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
}

