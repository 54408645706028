.feature-section {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 450px;
    perspective: 1000px;
    cursor: pointer;
}

.feature-block {
    position: absolute;
    width: 280px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.8s ease, opacity 0.8s ease;
    z-index: 1; /* Default z-index for all feature blocks */
}

.feature-block.hidden {
    opacity: 0;
    z-index: 0; /* Ensure hidden cards are behind everything */
    transform: scale(0.8) translateX(0);
}

.feature-block.left {
    transform: translateX(-250px) scale(0.9) rotateY(10deg);
    opacity: 0.6;
    z-index: 2; /* Reduce z-index to keep it below other elements */
}

.feature-block.right {
    transform: translateX(250px) scale(0.9) rotateY(-10deg);
    opacity: 0.6;
    z-index: 2; /* Reduce z-index to keep it below other elements */
}

.feature-block.active {
    transform: translateX(0) scale(1.1);
    opacity: 1;
    z-index: 3; /* Active card should be slightly above left/right, but still below other important elements */
}

.icon-wrapper {
    position: relative;
    width: 60px;
    height: 60px;
    margin: 0 auto 10px;
}

.feature-icon {
    width: 100%;
    height: 100%;
}

.circle-outline {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #007bff;
    box-sizing: border-box;
}

.feature-heading {
    font-size: 1.5rem;
    margin: 10px 0;
    font-weight: bold;
    color: #007bff;
}

.feature-subheading {
    font-size: 1.2rem;
    margin: 5px 0;
    color: #007bff;
}

.feature-description {
    font-size: 1rem;
    color: #777;
}

@media (max-width: 768px) {
    .feature-section {
        height: 500px;
    }

    .feature-block {
        width: 260px;
    }

    .feature-block.left, .feature-block.right {
        transform: translateX(200px) scale(0.8);
    }
}
