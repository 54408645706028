.organization-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    overflow: hidden;
}

.organization-header, .organization-row {
    display: grid;
    grid-template-columns: 1fr repeat(7, 100px);
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #e1e1e1;
}

.organization-header {
    background-color: #f5f5f5;
    font-weight: bold;
}

input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
    width: 20px;
    height: 20px;
    cursor: not-allowed; /* Since checkboxes are disabled */
}
