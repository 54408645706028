/* Container styles */
.client-screens-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden;
}

/* Header styles */
.client-screens-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.client-screens-download-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.client-screens-download-btn:hover {
    background-color: #0056b3;
}

/* Table rows */
.client-screens-table {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 400px; /* Set the maximum height for the scrollable area */
    overflow-y: auto; /* Enable vertical scrolling */
    padding-right: 1rem; /* Add padding to prevent scrollbar overlap */
}

.client-screens-row {
    display: grid;
    grid-template-columns: repeat(10, 1fr); /* Match the column count */
    gap: 1rem;
    padding: 0.75rem;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: background-color 0.2s ease-in-out;
}

.client-screens-row:hover {
    background-color: #f7f7f7;
}

.client-screens-cell {
    overflow-wrap: break-word;
    font-size: 0.9rem;
    color: #555;
}

/* Status badges */
.client-screens-status-badge {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
}

.status-completed {
    background-color: #d1f7c4;
    color: #1a7f37;
}

.status-in-progress {
    background-color: #fff5ba;
    color: #856404;
}

.status-pending {
    background-color: #ececec;
    color: #6c757d;
}

/* Results */
.client-screens-result-item {
    display: flex;
    align-items: center;
    gap: 5px;
}

.client-screens-lab-check-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: lightcoral;
}

/* No data message */
.client-screens-no-data-message {
    text-align: center;
    color: #666;
    font-size: 1rem;
    margin-top: 1rem;
}
