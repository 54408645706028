.specimen-notes-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    z-index: 1000;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
    padding-bottom: 10px;
}

.close-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

.modal-content {
    padding-top: 10px;
}

.specimen-note {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}