/* InvoiceItem.css */

.invoice-item {
    margin-bottom: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
}

.invoice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.invoice-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.line-items-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.line-items-table th,
.line-items-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.invoice-item-group-0 {
    background-color: #f9f9f9;
}

.invoice-item-group-1 {
    background-color: #ffffff;
}

.invoice-item-group-0 td,
.invoice-item-group-1 td {
    border-bottom: 1px solid #ccc; /* Add bottom border for visual separation */
}

.group-start {
    border-top: 10px solid #4F4F4F; /* Add top border for the first item in each group */
}

.invoice-item-description-row,
.invoice-item-quantity-row,
.invoice-item-labb-cost-row,
.invoice-item-retail-price-row,
.invoice-item-specimen-id-row,
.invoice-item-panel-codes-row {
    padding: 8px;
}

.invoice-item-panel-codes-row span {
    display: inline-block;
    margin-right: 5px;
    padding: 2px 5px;
    background-color: #4F4F4F;
    color: #fff;
    border-radius: 3px;
}

.invoice-cost-summary {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.cost-bubble {
    padding: 10px;
    border-radius: 5px;
    background-color: #eee;
    text-align: center;
    font-weight: bold;
}

.net-loss {
    background-color: #f8d7da;
}

.net-gain {
    background-color: #d4edda;
}

.invoice-client-cost,
.invoice-labb-cost {
    background-color: #e2e3e5;
}

@media (max-width: 768px) {
    .invoice-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .invoice-details {
        flex-direction: column;
        align-items: flex-start;
    }

    .invoice-details span {
        margin-bottom: 10px;
    }
}
