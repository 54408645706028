.notification-modal {
    position: fixed;
    top: 60px;
    right: 0;
    width: 300px; /* Fixed width for consistency */
    height: calc(100vh - 60px);
    background-color: #fff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 15px 0 0 15px;
    overflow-y: auto;
    z-index: 1000;
    border: 1px solid #e0e0e0; /* Subtle border */
}

.notification-modal-header {
    padding: 15px;
    background-color: #f8f8f8; /* Slightly different background for header */
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    border-top-left-radius: 14px; /* Match parent's border-radius */
}

.notification-modal-content {
    padding: 15px;
}
