
/* CSS (SpeedSwabsHeroGraphic.css) */
.speed-swabs-hero-graphic {
    position: fixed;
    top: 150px; /* Adjust as needed for spacing */
    right: 0;
    height: 100%;
    z-index: 0;
}

.graphic-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 100%;
}

.speed-swabs-image {
    width: auto;
    max-width: 700px; /* Adjust the max width for better fitting */
    max-height: 900px; /* Adjust max height */
    object-fit: contain;
    transition: transform 0.1s ease-out;
}

@media (max-width: 992px) {
    .speed-swabs-image {
        margin-right: -15%;
        max-width: 500px; /* Adjust the max width for better fitting */
        max-height: 700px; /* Adjust max height */
    }
}

@media (max-width: 768px) {
    .speed-swabs-hero-graphic {
        display: none;
    }
}