

/* CSS (SpeedSwabsFeaturesUltimate.css) */
.ultimate-features-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #ffffff;
}

.ultimate-features-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}

.ultimate-swab-image-container {
    flex: 1;
}

.ultimate-swab-image {
    width: auto;
    height: 60%;
    max-height: 600px;
    transition: transform 0.3s ease;
}

.ultimate-features-content {
    flex: 2;
    text-align: left;
    max-width: 800px;
    color: #706F70;
}

.ultimate-features-icons {
    display: flex;
    justify-content: start;
    gap: 1rem;
    margin-bottom: 1rem;
    position: relative;
    height: 100px;
}

.ultimate-feature-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.8s ease, opacity 0.8s ease;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(0);
    opacity: 0;
}

.ultimate-feature-icon-container.hidden {
    opacity: 0;
    transform: scale(0.8);
    z-index: 0;
}

.ultimate-feature-icon-container.next {
    transform: translateX(150%) scale(0.9) rotateY(-10deg);
    opacity: 0.6;
    z-index: 2;
}

.ultimate-feature-icon-container.active {
    transform: translateX(0) scale(1.1);
    opacity: 1;
    z-index: 3;
}

.ultimate-feature-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 0.5rem;
}

.ultimate-icon-header {
    font-weight: bold;
    text-align: center;
    font-size: 0.9rem;
}