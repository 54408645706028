/* LabbLogin component styles */
.login-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ECEDED;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login-input {
    flex: 1;
    margin: 0.5rem 0;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    max-width: calc(95% - 0.5rem); /* Limit input width to 50% minus margin */
}

.email-input::placeholder,
.password-input::placeholder {
    color: #aaa;
}

/* Responsive design */
@media (max-width: 1050px) {
    .login-form {
        flex-direction: column;
        align-items: stretch; /* Stretch to full width */
        padding: 0.5rem;

    }

    .login-input {
        margin: 0.5rem 0;
        max-width: 100%; /* Full width on smaller screens */
    }
}
