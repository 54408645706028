.orders-page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f5f5f5; /* Add a light background color */
}

.orders-header-container {
    flex-shrink: 0;
}

.orders-body-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.orders-content-container {
    display: flex;
    flex: 1;
    gap: 20px;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start; /* Align items to the top */
}

.orders-table-container, .product-pick-list {
    max-height: 90vh; /* Adjust based on your layout */
    overflow-y: auto; /* Allows vertical scrolling */
}

.orders-table-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFF;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin-bottom: 20px;
    max-width: 1200px; /* Add a max-width for better control */
}

@media (max-width: 768px) {
    .orders-content-container {
        flex-direction: column;
    }
}
