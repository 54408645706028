/* AuthenticatedWrapper.css */

.full-screen-modal {
    position: fixed; /* Fixed position to stay in place even on scroll */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1000; /* High z-index to overlay on top of other content */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    /* Adjust width and height as needed */
    width: 80%;
    height: 80%;
    overflow-y: auto; /* Add scroll for content overflow */
}
