.speed-swabs-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f0f0f0;
    padding: 10px 20px;
    position: fixed; /* Make the header sticky */
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Smooth transition */
    opacity: 1; /* Fully visible */
}

.speed-swabs-header.hidden {
    transform: translateY(-100%); /* Move header out of view */
    opacity: 0; /* Hide header */
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    height: 50px;
}

.header-right {
    display: flex;
    align-items: center;
}

.navbar-container {
    display: flex;
    margin-right: 20px;
}

.nav-item {
    margin-left: 10px;
}

.nav-icon {
    width: 30px;
    height: 30px;
}

.user-icon-card {
    margin-left: 20px;
}
