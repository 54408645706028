

.speed-swabs-features {
   padding: 3rem;
   background-color: #ffffff;
   border-radius: 16px;
}

.comparison-table {
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
}

.comparison-table-row {
    display: table-row;
    transition: background-color 0.3s ease;
}

.comparison-table-row.header {
    background-color: #e0e0e0;
    font-weight: bold;
}

.table-cell {
    display: table-cell;
    padding: 1.5rem;
    vertical-align: middle;
    border-bottom: 1px solid #dcdcdc;
}

.criteria {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.criteria-header {
    font-weight: bold;
    font-size: 1.2rem;
}

.criteria-subheader {
    font-size: 0.9rem;
    color: #555;
}

.icon-and-text {
    display: flex;
    align-items: center;
}

.custom-check-icon,
.custom-cross-icon {
    width: 28px;
    height: 28px;
    margin-right: 12px;
}

.text-container {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.text-header {
    font-size: 1.1rem;
    font-weight: bold;
}

.success-subheader,
.fail-subheader {
    font-size: 0.85rem;
    color: #555;
}

.speed-swabs {
    color: #007bff;
}

.other-tests {
    color: #f44336;
}

.comparison-table-row:hover {
    background-color: #f0f0f0;
    cursor: pointer;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}