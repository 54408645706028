.filter-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border: 1px solid #ECEDED;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 2;
    background: #fff;
    margin-bottom: 0px;
}

.filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.filter-actions {
    display: flex;
    justify-content: flex-end; /* Example to align ActionTray to the right */
}



.filter-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.client-selector,
.location-selector {
    flex-grow: 1;
    margin-right: 10px;
}

.date-range-row {
    display: flex;
    justify-content: center;
    padding: 5px;
}
