.quest-account-row {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 10px;
}

.custom-select {
    flex-grow: 1;
    margin-right: 20px;
}

.react-select__control {
    border: 1px solid #ccc;
    border-radius: 20px;
    box-shadow: none;
}

.react-select__menu {
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.organization-details > span {
    margin-right: 15px;
    font-size: 14px;
}