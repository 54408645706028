.custom-dropdown {
    position: relative;
    width: 80%;
    margin: 10px;
    font-family: Montserrat, sans-serif;
    color: #58585a;
    z-index: 5000;
}

.dropdown-search-input {

    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    font-size: 1rem;
    color: #555;
    background-color: #ffffff;
    appearance: none;
    cursor: pointer;
}

.dropdown-search-input::placeholder {
    font-size: 14px;
    opacity: 0.7;
    font-weight: 400;
}
.dropdown-list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1001;
    width: 100%;
    border: 1px solid #58585a;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    overflow-x: hidden;
    transition: all 0.3s ease;
}

.custom-dropdown .dropdown-list.show {
    display: block;
    position: absolute;
    z-index: 500;
}

.dropdown-input-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.dropdown-clear-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    cursor: pointer;
    z-index: 10;
}


.dropdown-list.show {
    display: block;
}

.dropdown-item {
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.dropdown-item:hover {
    background-color: #f5f5f5;
}

.dropdown-list::-webkit-scrollbar {
    width: 8px;
}

.dropdown-list::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.dropdown-list::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.dropdown-list::-webkit-scrollbar-track {
    background: #f5f5f5;
}
