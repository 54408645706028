/* General layout for the order billing container */
.order-billing-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    width: 100%;
}

/* Styling each order card */
.order-card {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.order-card:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Order header styling */
.order-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.order-date-time {
    font-size: 14px;
    color: #333;
}

.order-time {
    font-size: 18px;
    font-weight: bold;
}

.order-date {
    font-size: 14px;
    color: #666;
}

.order-number {
    font-size: 22px;
    font-weight: bold;
    color: #444;
    text-align: right;
}

/* Payee and Payment Container */
.payee-payment-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
    text-align: left;
}

.payee-info-box {
    flex: 1;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
    font-size: 14px;
    color: #333;
}

.payee-name {
    font-weight: bold;
    font-size: 16px;
}

.payee-address {
    font-size: 14px;
    color: #555;
    line-height: 1.5;
}

.payment-status-box {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    text-align: center;
    border-radius: 8px;
    padding: 10px 20px;
    margin-top: 10px;
    transition: background-color 0.3s ease;
}

.payment-status-box:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

/* Line items table */
.line-items-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.line-items-table th,
.line-items-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    color: #333;
}

.line-items-table td:nth-child(2),
.line-items-table td:nth-child(3) {
    text-align: center; /* Center align quantity and price */
}

/* Order summary */
.order-summary {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    color: #333;
    font-size: 16px;
    margin-top: 10px;
    border-top: 1px solid #e0e0e0;
    padding-top: 10px;
}

.payment-link a {
    color: #007bff;
    font-weight: bold;
    text-decoration: none;
}

.payment-link a:hover {
    text-decoration: underline;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .order-billing-container {
        padding: 15px;
    }

    .order-card {
        padding: 15px;
    }

    .order-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .payee-payment-container {
        flex-direction: column;
        text-align: left;
    }

    .line-items-table th,
    .line-items-table td {
        padding: 8px;
        font-size: 13px;
    }

    .order-summary {
        flex-direction: column;
        gap: 10px;
    }

    .payment-link a {
        font-size: 14px;
    }
}

@media (max-width: 576px) {
    .order-card {
        padding: 10px;
    }

    .order-header .order-number {
        font-size: 18px;
    }

    .order-time {
        font-size: 16px;
    }

    .payee-name {
        font-size: 14px;
    }

    .payee-address {
        font-size: 12px;
    }

    .payment-status-box {
        font-size: 14px;
        padding: 8px 15px;
    }

    .line-items-table th,
    .line-items-table td {
        font-size: 12px;
    }

    .order-summary {
        font-size: 14px;
    }

    .payment-link a {
        font-size: 13px;
    }
}
