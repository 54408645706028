/* Container for the entire customer details section */
.customer-details-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Individual customer card */
.customer-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

/* Headings for sections */
.customer-card h1 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
}

/* Paragraph styling for customer details */
.customer-card p {
    margin: 5px 0;
    font-size: 16px;
    color: #666;
}

/* Bold styling for labels */
.customer-card p span.label {
    font-weight: bold;
    color: #333;
}

/* Hover effect on customer card */
.customer-card:hover {
    border-color: #999;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
}


/* General container for the Shopify Home page */
.shopify-home-container {
    font-family: 'Arial', sans-serif;
    padding: 20px;
    background-color: #f8f9fa;
    color: #333;
}

/* Header container */
.shopify-header-container {
    margin-bottom: 20px;
}

/* Section containing the text information */
.shopify-text-container {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Individual paragraphs */
.shopify-text-container p {
    margin: 0 0 15px 0;
    line-height: 1.6;
    font-size: 16px;
}

.shopify-text-container p:last-child {
    margin-bottom: 0;
}

/* Add a subtle hover effect (optional) */
.shopify-text-container:hover {
    background-color: #f1f1f1;
    transition: background-color 0.3s ease;
}
