.specimen-note {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    transition: background-color 0.2s ease;
}

.specimen-note:hover {
    background-color: #f0f8ff; /* Light blue on hover */
}

.specimen-note-header {
    display: flex;
    justify-content: space-between; /* Place subject and timestamp on opposite ends */
    font-weight: bold;
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
}

.specimen-note-subject {
    flex: 1; /* Allow the subject to take available space */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Prevent text from wrapping */
}

.specimen-note-timestamp {
    font-size: 12px;
    color: #666;
    margin-left: 10px;
}

.specimen-note-description {
    font-size: 13px;
    color: #555;
    line-height: 1.5;
    word-wrap: break-word; /* Handle long text gracefully */
}
