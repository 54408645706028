.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11000; /* High z-index to ensure it's on top */
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 850px; /* Adjust width as necessary */
    position: relative;
    display: flex;
    flex-direction: column;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    border: none;
    background: none;
}

/* Additional Styling for better visibility and interaction */
button:focus {
    outline: none; /* Removes default focus outline */
}

button:active {
    transform: scale(0.98); /* Subtle click effect */
}
