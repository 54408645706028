.speed-swabs-footer {
    background-color: #4F4F4F;
    text-align: center;
    padding: 15px 0;
    width: 100%;
    position: relative; /* Let the footer naturally fall at the bottom of the page */
    font-size: 14px;
    font-weight: bold;
    z-index: 9999; /* Ensure it appears above other content */
    margin-top: 50px; /* Add margin to provide spacing above the footer */
}

.speed-swabs-footer-text {
    color: #ffffff;
    text-decoration: none;
    margin: 0;
}

/* For mobile devices */
@media (max-width: 576px) {
    .speed-swabs-footer {
        font-size: 12px;
        padding: 10px 0;
    }
}
