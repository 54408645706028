/* Cart panel section container */
.cart-panel-section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 30px;
    background-color: #f9f9f9;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Drop shadow */
}

/* Product image container */
.cart-panel-image {
    margin-right: 20px;
}

.cart-panel-image img {
    width: 80px;
    height: auto;
}

/* Content section next to the image */
.cart-panel-content {
    flex-grow: 1;
    text-align: left;
}

/* Panel title for upgrade info */
.cart-panel-title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Drug details displayed in a grid */
.drug-details {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* Individual drug item */
.drug-item {
    background-color: #e7e7e7;
    padding: 8px;
    border-radius: 8px;
    font-size: 0.85rem;
    font-weight: bold;
    color: #333;
    min-width: 50px;
    text-align: center;
}

/* Highlight drugs added when upgrading */
.drug-item.added {
    background-color: #28a745;
    color: white;
}

/* Button container for action buttons */
.cart-panel-buttons {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

/* Learn more and Replace cart buttons */
.learn-more-btn,
.replace-cart-btn {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
}
/* Learn more and Replace cart buttons */
.learn-more-btn{
    background-color: #00A48A;
    color: white;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
}

.learn-more-btn:hover,
.replace-cart-btn:hover {
    background-color: #0056b3;
}

.replace-cart-btn {
    background-color: #28a745;
}
