/* Header Container */
.public-freddy-header {
    background-color: #F0F0F0; /* Match the Shopify background */
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

/* Container for all header content */
.public-freddy-header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

/* Left Section (Logo) */
.public-freddy-header__left {
    display: flex;
    align-items: center;
}

.public-freddy-header__logo-link {
    display: inline-block;
}

.public-freddy-header__logo {
    height: 50px; /* Match the height from the Shopify header */
    width: auto;
}

/* Right Section (Icons) */
.public-freddy-header__right {
    display: flex;
    align-items: center;
    gap: 15px; /* Slight space between icons */
}

.public-freddy-header__icon-link {
    display: flex;
    align-items: center;
}

.public-freddy-header__icon-link:hover {
    cursor: pointer;
}

/* Icons inside the right section */
.nav-icon {
    width: 30px;
    height: 30px;
}
