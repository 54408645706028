
/* ProductTitle.css */
.speed-swabs-product-details-product-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1.2;
    text-align: left;
    color: #706F70;
}

.highlight {
    color: #007BFF;
}
