.read-tiles-main-body{
    height: 90%;
    position: fixed;
    bottom: 125px;
    width: 100%;
    padding-bottom: 20px;
    padding-top: 56px;
    top: 125px;
    display: flex;
    flex-direction: row;
    overflow-x: auto; /* add scrolling */
    -ms-overflow-style: none;  /* Internet Explorer 10+ remove scroll bar */
    scrollbar-width: none;  /* Firefox remove scroll bar */
    z-index: 1;
    justify-content: flex-start;
    flex-wrap: wrap;
}


.read-tiles-main-body::-webkit-scrollbar {
    display: none;  /* Safari and Chrome remove scroll bar*/
}
