.badganator-main-frame {
    /* The height here is the default. The inline style in the component will override it when a collector is selected. */
    height: 65vh;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.badganator-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    height: 50px;
}

.badganator-collector-selector {
    width: 450px;
    margin-right: 100px;
}

.multi-selector-user {
    font-family: sans-serif;
    z-index: 3;
}

.get-collector-badge-button-frame {
    margin-right: 100px;
}

.get-collector-badge-button {
    background-color: #0F5EA5;
    color: white;
    font-weight: bold;
    padding: 0.5rem 2rem;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 1rem;
}

.get-collector-badge-button:hover {
    opacity: 0.8;
}

.pre-set-report-icon-frame {
    width: 40px;
    margin-right: 1rem;
    cursor: pointer;
}

.pre-set-report-icon-frame:hover {
    opacity: 0.8;
}

/* New styling for the collector details bubble */
.collector-details-bubble {
    margin-top: 1rem;
    background-color: #f5f5f5;
    padding: 1rem;
    border-radius: 4px;
    font-family: sans-serif;
}
