/* HeaderRow.css */

.organizations-table-header-row {
    background-color: #f7f9fc;
    color: #333;
    font-weight: bold;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.organizations-table-header-cell {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}
