.how-to-use-section {
    background-color: rgba(0, 86, 179, 0.75);
    color: #fff;
    border-top-right-radius: 100vh;
    border-bottom-right-radius: 100vh;
    height: 100%;
    max-width: 1800px;
    overflow: hidden;
    padding-left: 15%;
    padding-right: 10%;
}

.how-to-use-heading {
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
    margin-top: 40px;
    margin-bottom: 10px;
    padding-left: 0;
}

.how-to-use-heading-container {
    text-align: left;
}

.how-to-use-description {
    font-size: 1rem;
    text-align: left;
    max-width: 800px;
    margin: 0 0 20px;
    color: #ffffff;
    padding-left: 0;
}

.how-to-use-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    padding-left: 0;
}

.how-to-use-step {
    max-width: 350px;
    text-align: center;
}

.image-container {
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 12px;
    overflow: hidden;
    background-color: #f0f0f0;
    margin: 0 auto 20px;
}

.step-pill {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #7f7f7f;
    padding: 10px;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 12px;
    font-weight: bold;
    font-size: 1.4rem;
    font-family: 'AutoWide', sans-serif;
}

.how-to-use-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.step-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25%;
    background-color: rgba(112, 111, 112, 0.8);
    padding: 10px;
    text-align: center;
    box-sizing: border-box;
}

.step-title {
    font-size: 2rem;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.step-action {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0;
    color: #ffffff;
}

.step-time {
    font-size: 0.9rem;
    font-weight: normal;
    margin-top: 5px;
}