.billing-page-invoice-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.screens-header-container {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 10px; /* Reduce padding around the header */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.billing-section {
    flex: 1; /* Allow the section to take up remaining space */
    overflow-y: auto; /* Enable vertical scrolling */
    display: flex;
    flex-direction: column;
}

.screens-content-container {
    flex: 1; /* Allow the container to take up remaining space */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 10px; /* Add some padding for better spacing */
}

.net-gain-loss-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 20px;
}

.net-gain-loss-summary .overall-client-cost,
.net-gain-loss-summary .overall-labb-cost {
    font-size: 1.5em;
    font-weight: bold;
}

.net-gain-loss-summary .overall-net-gain-loss {
    font-size: 2em;
    font-weight: bold;
}

.cost-bubble {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 1.5em;
    font-weight: bold;
    background-color: #f1f1f1;
    margin: 0 10px;
}

.net-gain {
    color: green;
}

.net-loss {
    color: red;
}
