.client-card {
    background: #4F4F4F;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 300px; /* Ensure cards have a max-width */
    transition: transform 0.3s ease; /* Add transition for hover effect */
    height: fit-content;
}

.client-card:hover {
    height: fit-content;
    transform: translateY(-5px); /* Lift effect on hover */
}

.client-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    color: #4a4a4a; /* Darker color for better readability */
}

.order-actions {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 10px;
}
