
/* CSS (NavBubble.css) */
.nav-bubble-container {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
}

.nav-bubble {
    display: inline-flex;
    background: #707070;
    color: #fff;
    padding: 3px 7px;
    border-radius: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.bubble-button {
    background: none;
    border: none;
    padding: 8px 15px;
    font-size: 12px; /* Reduced size */
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.bubble-button.active {
    background-color: #0073e6;
    color: white;
    border-radius: 15px;
    transform: scale(1.05); /* Slight scaling */
}

.bubble-button:hover {
    transform: scale(1.03);
}

.bubble-content {
    margin-top: 10px;
}


@media (max-width: 1200px) {
    .nav-bubble{
        margin-top: 10%;
    }
}

@media (max-width: 992px) {
    .nav-bubble{
        margin-top: 10%;
    }
}