/* General styles for all screen sizes */
.fusion-dashboard-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f4f8;
    min-height: 100vh; /* Ensures full height for all devices */
    padding: 20px;
}

.fusion-dashboard-content {
    text-align: center;
    background: #ffffff;
    padding: 40px 30px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    transition: padding 0.3s ease, box-shadow 0.3s ease;
}

.dashboard-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
}

.dashboard-message {
    font-size: 16px;
    color: #666;
    margin-bottom: 30px;
}

.login-button-container {
    display: flex;
    justify-content: center;
}

.login-button-container .PrimaryButton {
    width: 100%;
    font-size: 18px;
    padding: 12px 20px;
    background-color: #0073e6;
    color: white;
    border: none;
    border-radius: 8px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.login-button-container .PrimaryButton:hover {
    background-color: #005bb5;
    transform: translateY(-2px); /* Adds a slight lift effect on hover */
}

/* Adjustments for larger screens (desktops and tablets) */
@media (min-width: 768px) {
    .fusion-dashboard-content {
        padding: 50px 40px;
        max-width: 500px;
    }

    .dashboard-title {
        font-size: 28px;
    }

    .dashboard-message {
        font-size: 18px;
    }

    .login-button-container .PrimaryButton {
        font-size: 20px;
    }
}

/* Scaling for smaller devices (large phones and tablets) */
@media (max-width: 768px) {
    .fusion-dashboard-content {
        padding: 30px 20px;
    }

    .dashboard-title {
        font-size: 22px;
    }

    .dashboard-message {
        font-size: 15px;
    }

    .login-button-container .PrimaryButton {
        font-size: 16px;
    }
}

/* Styling for small phones */
@media (max-width: 576px) {
    .fusion-dashboard-content {
        padding: 20px 15px;
    }

    .dashboard-title {
        font-size: 20px;
    }

    .dashboard-message {
        font-size: 14px;
    }

    .login-button-container .PrimaryButton {
        font-size: 14px;
        padding: 10px 16px;
    }
}
