.client-sites-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.client-sites-modal {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    max-width: 900px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
}

.client-sites-modal-header {
    display: flex;
    justify-content: flex-end;
}

.client-sites-modal-content {
    overflow-y: auto;
    max-height: 500px;
    margin-top: 20px;
}

.client-site-detail {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-bottom: 1px solid #ccc;
    border-radius: 6px;
    background-color: #f9f9f9;
    margin-bottom: 10px;
}

.client-site-info h3.site-name {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 5px 0;
}

.client-site-info p {
    margin: 5px 0;
    font-size: 14px;
}

.site-address {
    font-style: italic;
}

.no-client-sites {
    text-align: center;
    font-size: 16px;
    color: #888;
    margin-top: 20px;
}

.selected-site {
    background-color: #f0f8ff; /* Light blue when selected */
    border: 2px solid #0078d7; /* Highlighted border for clarity */
    padding: 10px;
}