.box-style-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.box-style-options .box-image-large {
    width: 100px; /* Adjust as needed for larger size */
    height: 100px;
    cursor: pointer;
}
