/* Wrapper for the entire cart item, including the larger image */
.speed-cart-item-wrapper {
    display: flex;
    flex-direction: row; /* Align image and cart item side by side */
    align-items: center; /* Vertically center */
    width: 100%;
    margin: 5px;
}

/* Image container with rounded corners, made larger than the rest of the cart item */
.speed-cart-item-image-container {
    position: relative;
    width: 200px; /* Larger image width */
    height: 200px; /* Larger image height */
    border-radius: 12px;
    background-color: #f0f0f0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Drop shadow */
    z-index: 1; /* Ensure the image is on top of the cart item */
}

.speed-cart-item-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 12px 0 0 12px;
}

.speed-cart-item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 15px;
    font-size: 12px;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    z-index: 2; /* Ensure overlay is on top of the image */
}

.panel-number {
    font-weight: bold;
}

/* Title overlay over the image */
.speed-cart-product-title-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 10px;
    font-size: 12px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    z-index: 3; /* Ensure the title overlay is on top of the image */
    width: 100%;
    word-wrap: break-word;
}

.speed-cart-product-title {
    font-weight: bold;
    line-height: 1.4;
    white-space: normal; /* Allow the text to wrap onto multiple lines if needed */
}

/* Main cart item container */
.speed-cart-item {
    display: flex;
    align-items: center;
    background-color: #e9f2f9;
    border-radius: 0 70px 70px 0; /* Rounded right side only */
    width: 70%;
    position: relative;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle drop shadow */
    z-index: 0; /* Lower than the image container */
}

/* Quantity controls */
.speed-cart-item-quantity-controls {
    align-items: center;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #e9f2f9;
    border-radius: 0px 70px 70px 0; /* Full circle rounded on the right side */
    position: relative;
}

.speed-cart-item-info {
    width: 100%;
}

.plus-button {
    border-radius: 0 70px 0 0;
    background-color: rgba(0, 164, 138, 0.3); /* Green #00A48A at 30% opacity */
    color: #00A48A; /* Text color to match the button */
}

.minus-button {
    border-radius: 0 0 70px 0;
    background-color: rgba(231, 231, 230, 0.3); /* Gray #E7E7E6 at 30% opacity */
    color: #E7E7E6; /* Text color to match the button */
}

.quantity-button {
    font-size: 28px;
    cursor: pointer;
    height: 50%; /* Buttons are half the height of the controls */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none; /* Remove border */
}

/* Quantity circle */
.quantity-circle {
    background-color: #007bff;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); /* Center the quantity circle above the buttons */
}

/* Final price circle */
.speed-cart-item-final-price {
    background-color: #ffffff;
    color: #707070;
    font-weight: bold;
    font-size: 18px;
    border-radius: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    right: 0;
    position: absolute;
    width: 140px; /* Ensure it's a circle */
}
