/* Input container with space */
.input-container {
    position: relative;
    width: 100%;
    margin-bottom: 1.5rem; /* Space between the inputs */
}

/* Input Styling */
.talkswabs-input {
    padding: 1rem;
    padding-left: 2.5rem; /* Space for the icon */
    width: 100%;
    border: 1px solid #dcdcdc;
    border-radius: 50px; /* Rounded corners for pill-like style */
    font-size: 1rem;
    box-sizing: border-box;
    transition: border-color 0.3s;
}

.talkswabs-input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Select container with pill style */
.talkswabs-select {
    width: 100%;
    padding: 1rem;
    border: 1px solid #dcdcdc;
    border-radius: 50px; /* Rounded corners for pill-like style */
    font-size: 1rem;
    box-sizing: border-box;
    transition: border-color 0.3s;
    margin-bottom: 1.5rem; /* Space between selects */
}

.talkswabs-select:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Multi-select options with pill style */
.multi-select-options {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* Space between options */
}

.multi-select-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1.5rem;
    border: 1px solid #dcdcdc;
    border-radius: 50px; /* Rounded corners for pill-like style */
    background-color: #f1f1f1;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Selected state for multi-select */
.multi-select-option.selected {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

/* Checkbox and label spacing */
.multi-select-option label {
    font-size: 1rem;
    cursor: pointer;
}

.multi-select-option input[type="checkbox"] {
    margin-right: 0.5rem;
}
