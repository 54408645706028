/* Logout.css */

.logout-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.logout-btn:hover {
    transform: scale(1.1);
}