/*freddy-react/src/components/specimen_tacking/screens/header_row/HeaderRow.css*/

.header-row {
    display: flex;
    justify-content: space-between;
    background-color: #f2f2f2;
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
}

.header-cell {
    flex: 1;
    text-align: center;
    font-weight: bold;
    padding: 5px 10px;
}

