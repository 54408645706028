/* Container for the whole component */
.services-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;
    background-color: #f8f9fa;
    border-radius: 16px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
}

/* Actions container for Select All button */
.actions {
    margin-bottom: 20px;
}

/* Select All button */
.select-all-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.select-all-button:hover {
    background-color: #0056b3;
}

/* Panels column layout */
.panels-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

/* Service group container */
.service-group {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.service-group:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Service code heading */
.service-group h3 {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
}

/* Panel item within service group */
.panel-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.panel-item:hover {
    transform: scale(1.02);
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.15);
}

/* Selected panel item styling */
.panel-item.selected {
    border: 2px solid #007bff;
    background-color: #eaf4ff;
}

/* Test code names section */
.panel-details {
    width: 100%;
}

.panel-details p {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
}

.panel-details ul {
    padding-left: 20px;
    margin: 0;
}

.panel-details li {
    list-style: none;
    font-size: 13px;
    color: #555;
}

/* Create button styling */
.create-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.create-button:hover {
    background-color: #0056b3;
}
