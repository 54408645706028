/* PricesModel.css */

.prices-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200; /* Ensure it is above all other elements */
}

.prices-modal {
    background-color: #fff;
    border-radius: 8px;
    padding: 0;
    width: 90%;
    max-width: 1200px; /* Optional: limit the modal width */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    max-height: 90vh; /* Ensure it fits within the viewport */
}

.prices-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
    background-color: #f9f9f9;
    position: sticky;
    top: 0; /* Make the header stick at the top when scrolling */
    z-index: 1; /* Ensure it stays above the content */
}

.prices-modal-header h2 {
    margin: 0;
    font-size: 18px;
}

.prices-modal-content {
    overflow-y: auto;
    flex-grow: 1; /* Allow this section to grow and take available space */
    padding: 16px;
}

.prices-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
}

.prices-save-action {
    background-color: #ECEDED;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border-top: 1px solid #eee;
    position: sticky;
    bottom: 0; /* Make the footer stick at the bottom when scrolling */
    z-index: 1; /* Ensure it stays above the content */
}
