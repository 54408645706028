/* Main table styles */
.specimen-row {
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.specimen-row-expanded {
    background-color: #f3f3f3; /* Light grey to indicate expansion */
}

/* Expanded row details styles */
.specimen-row-detail td {
    background-color: #e9e9e9; /* Slightly darker grey for contrast */
    padding: 10px;
}

.specimen-row-detail-cell {
    grid-column: span 7; /* Make sure it spans all the columns */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Existing styles */
.specimen-row-cell-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.specimen-row-cell {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.specimen-row-cell .Tooltip {
    width: 100%;
}

.specimen-row-cell .Tooltip:hover .Tooltip-text {
    visibility: visible;
}

@media (max-width: 768px) {
    .specimen-row-detail-cell {
        grid-column: span 7; /* Adapt to single column layout */
    }
}
