.mro-specimens-table-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 100%;
}

.filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 10px;
}

.filter-select {
    min-width: 90vh;
}

.table-container {
    overflow-y: auto;
    height: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    height: 100%;
}

th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

thead th {
    background-color: #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 10;
}

tr:nth-child(odd) {
    background-color: #f9f9f9;
}

tr:nth-child(even) {
    background-color: #ffffff;
}
