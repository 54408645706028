/* src/components/product_prices/prices_row/PricesRow.css */

.product-price-row {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #f9f9f9;
}

.product-price-item {
    flex: 1;
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.product-name {
    flex: 2;  /* Allow product name to take more space */
}

.product-label {
    margin-right: 10px;
    font-weight: bold;
}

.product-name-value {
    padding: 6px 10px;
    background-color: #eaeaea;
    border-radius: 4px;
}

.product-price-input {
    padding: 6px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100px;
    transition: all 0.3s ease;
}

.product-price-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}
