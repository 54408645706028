/* Modal overlay covers the entire viewport with a semi-transparent background */
.badganator-modal-overlay {
    z-index: 5000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal content is a flex container that ensures its children (header and body) fit nicely */
.badganator-modal-content {
    z-index: 6000;
    position: relative;
    background-color: #fff;
    padding: 20px;
    width: 80vw;
    height: 65vh;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

/* Header: The close button is aligned to the right.
   (Other header elements now only include the select and buttons) */
.badganator-modal-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    z-index: 1;
}

/* Body: Takes up the remaining space and becomes scrollable if content overflows */
.badganator-modal-body {
    flex-grow: 1;
    overflow-y: auto;
}

/* Badganator main container */
.badganator-main-frame {
    height: 100%; /* Let the frame fill the modal's body */
    display: flex;
    flex-direction: column;
    padding: 20px;
}

/* Header area of Badganator (select & buttons) */
.badganator-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Adjust padding as needed */
    padding: 0 2rem;
    height: 50px;
}

/* Container for the select control */
.badganator-collector-selector {
    width: 450px;
    /* No margin-right needed here since buttons are in a separate container */
}

/* Container for the buttons (override and icons) */
.button-container {
    display: flex;
    align-items: center;
}

/* React-Select specific styling */
.multi-selector-user {
    font-family: sans-serif;
    z-index: 3;
}

/* Other button and icon styles */
.get-collector-badge-button-frame {
    margin-right: 100px;
}

.get-collector-badge-button {
    background-color: #0F5EA5;
    color: white;
    font-weight: bold;
    padding: 0.5rem 2rem;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 1rem;
}

.get-collector-badge-button:hover {
    opacity: 0.8;
}

.pre-set-report-icon-frame {
    width: 40px;
    margin-right: 1rem;
    cursor: pointer;
}

.pre-set-report-icon-frame:hover {
    opacity: 0.8;
}

/* Styling for the collector details bubble (rendered below the header) */
.collector-details-bubble {
    margin-top: 1rem;
    background-color: #f5f5f5;
    padding: 1rem;
    border-radius: 4px;
    font-family: sans-serif;
    /* Ensure it sits below the select and doesn't interfere with its dropdown */
    position: relative;
    z-index: 1;
}
