.shopify-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F0F0F0;
    padding: 10px 20px;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    height: 50px;
}

.header-right {
    display: flex;
    align-items: center;
}

.navbar-container {
    display: flex;
    margin-right: 20px;
}

.nav-item {
    margin-left: 10px;
}

.nav-icon {
    width: 30px;
    height: 30px;
}