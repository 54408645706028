/*src/components/invoices/invoice_filters/InvoiceFilters.css*/
.filter-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 10px 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.filter-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.filter {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
