.screener {
    width: 20%;
    max-width: 450px;
    min-width: 200px;
    height: fit-content;
    border: 2px solid #58585a;
    position: relative;
    background-color: #eceded;
    border-radius: 10px;
    margin-top: 1%;
    margin-left: 2%;
    font-family: Montserrat, sans-serif;
    color: #58585a;
}

.screener_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 5px;
    margin: 0;
    border-radius: 6px 6px 0 0;
    background-color: #ffffff;
    font-size: 12px;
    height: fit-content;
    border-bottom: 1px solid #58585a;
}

.screener_header_text {
    text-align: center;
    padding: 5px;
    margin: 0;
    border-radius: 6px;
    background-color: #ffffff;
    font-size: 15px;
}

.dark_grey_strip {
    width: 100%;
    background-color: #58585a;
    height: 6px;
}

.screener_info_section p {
    font-size: 1rem;
    color: #555;
    margin: 0;
}
.screener_info_section {
    padding: 10px 15px;
}

.screener-searchable-dropdown {
    width: 80%;
    padding: 8px;
    margin: 10px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    font-size: 1rem;
    color: #555;
    background-color: #ffffff;
    appearance: none;
    cursor: pointer;
}

.screener_dropdown_text {
    width: 100%;
    padding: 8px;
    margin: 10px;
    font-size: 1rem;
    color: #555;
    appearance: none;
}
