/* Navigation.css */
.talk-swabs-button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem; /* Space between the buttons */
}

.talk-swabs-secondary-button,
.talk-swabs-primary-button {
    flex: 1;
    max-width: 48%; /* Ensures both buttons are of equal size */
    text-align: center;
}
