.auth-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
}

.auth-background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.3; /* Set background opacity */
    z-index: 0;
}

.auth-card {
    position: relative;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: calc(100% - 20px); /* Ensure 10px padding from each side */
    max-width: 400px;
    text-align: center;
    z-index: 1;
}

.auth-logo {
    max-width: 150px;
    margin-bottom: 20px;
}

.auth-form {
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}

.auth-input {
    width: calc(100% - 20px); /* Wider input to fill more space */
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.auth-btn {
    width: calc(100% - 20px); /* Wider button to match input fields */
    padding: 10px;
    margin-top: 10px;
}

.auth-input-container {
    width: 100%;
    margin-bottom: 10px;
}

.auth-confirmed-bubble {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    font-size: 16px;
    background-color: #e0e0e0;
    border-radius: 20px;
    text-align: center;
    box-sizing: border-box;
    width: calc(100% - 40px); /* Adjust to leave space for the icon button */
}

.auth-error-message {
    color: red;
    margin-top: 10px;
}
