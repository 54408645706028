/* Laboratory Page */
.laboratory-page-container {
    font-family: Arial, sans-serif;
    background-color: #f8f9fc;
    padding: 20px;
    min-height: 100vh;
}

.laboratory-filter-container {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
}

.laboratory-filter-container .rs-picker {
    min-width: 300px;
    max-width: 400px;
    width: 100%;
    z-index: 1050; /* Ensure it appears above other elements */
}

.laboratory-content-container {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

/* Records Table */
.laboratory-records-table {
    width: 100%;
    border-spacing: 0;
}

.laboratory-table-header {
    display: grid;
    grid-template-columns: 0.15fr 0.15fr 0.15fr 0.15fr 2fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
    background-color: #007bff;
    color: #ffffff;
    padding: 10px;
    border-radius: 8px;
    font-weight: bold;
}

.laboratory-table-body {
    margin-top: 10px;
}

.laboratory-table-row {
    display: grid;
    grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr 2fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
    align-items: center;
    gap: 2px;
    padding: 2px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}

.laboratory-table-row:nth-child(even) {
    background-color: #f8f9fc;
}

.laboratory-table-row:hover {
    background-color: #e7f0ff;
}

/* Actions */
.laboratory-actions-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification-set-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 10px;
    padding: 10px;
    background-color: #f1f9ff;
    border: 1px solid #007bff;
    border-radius: 8px;
    z-index: 1050;
}

/* Expanded Row */
.laboratory-expanded-content {
    grid-column: span 5;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    padding: 15px;
    background-color: #f1f9ff;
    border: 1px solid #007bff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.laboratory-expanded-content div {
    padding: 8px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* Input Field */
.laboratory-notes-input-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.laboratory-notes-input {
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
}

.laboratory-notes-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}
.notification-bell {
    background-color: #f8f9fc;
    border: 1px solid #007bff;
    border-radius: 50%;
    padding: 8px;
    transition: all 0.3s ease;
}

.notification-bell:hover {
    background-color: #007bff;
    color: #ffffff;
    cursor: pointer;
}

.notification-bell:focus {
    outline: none;
    box-shadow: 0 0 4px #007bff;
}

.notification-bell {
    background-color: #f8f9fc;
    border: 1px solid #007bff;
    border-radius: 50%;
    padding: 8px;
    transition: all 0.3s ease;
}

.notification-bell:hover {
    background-color: #007bff;
    color: #ffffff;
    cursor: pointer;
}

.notification-bell:focus {
    outline: none;
    box-shadow: 0 0 4px #007bff;
}



/* DateRangePicker Styles */
.rs-picker-menu {
    z-index: 1050 !important; /* Ensure it's above other elements */
}

.rs-picker {
    min-width: 300px;
    max-width: 400px;
    width: 100%;
}

.rs-picker-placeholder {
    font-size: 14px;
    color: #6c757d;
}

.rs-picker-toggle {
    border-radius: 8px;
    padding: 8px 12px;
    border: 1px solid #ddd;
    transition: border-color 0.3s;
}

.rs-picker-toggle:focus, .rs-picker-toggle:hover {
    border-color: #007bff;
}

/* Additional Style for Popup */
.rs-picker-menu {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 10px;
}
