.products-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    height: 100vh;
    gap: 20px;
}

.shopify-column, .lss-multi-column {
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 10px;
    flex-grow: 1;
    overflow-y: auto;
}

.shopify-column {
    width: 30%;
    max-height: 100%;
}

.lss-multi-column {
    width: 30%;
    max-height: 100%;
}

.product-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: relative;
    transition: transform 0.2s, box-shadow 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 350px; /* Limit height to make them smaller */
    overflow: hidden; /* Hide overflow content */
}

.product-item:hover {
    transform: scale(1.02);
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.15);
}

.product-item.selected {
    border: 2px solid #007bff;
    background-color: #eaf4ff;
}

.product-image {
    width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: cover;
    border-radius: 5px;
    margin-top: 10px;
}

.shopify-manage-products-modal {
    /*dispaly model in lower right corner*/
    top: 80%;
    right: 20px;
    transform: translateY(-50%);
    width: 300px;
    height: 250px;
    background-color: rgba(0, 0, 0, 0.8);
    align-items: center;
    position: fixed;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.shopify-manage-products-modal-content {
    display: flex;
    flex-direction: column;
}

.shopify-manage-products-modal h3 {
    margin: 0;
    font-size: 1.2rem;
}

.shopify-manage-products-modal p {
    margin: 10px 0;
}

.shopify-manage-products-modal button {
    align-self: flex-end;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.shopify-manage-products-modal button:hover {
    background-color: #0056b3;
}
