.primary-button,
.secondary-button,
.danger-button {
    padding: 4%;
    margin: 4%;
    border-radius: 5px;
    white-space: nowrap;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    cursor: pointer;
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon-button {
    border: none;
}

.primary-button {
    background: #2F80ED;
    color: #fff;
}

.primary-button.selected,
.secondary-button.selected,
.danger-button.selected {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: #2F80ED;
}

.secondary-button {
    background: #D8D8D8;
}

.danger-button {
    background: #EB5757;
}

.icon-button {
    background: transparent;
    box-shadow: none;
}

.icon-wrapper {
    width: 24px;
    height: 24px;
}
