

/* ProductPriceDetails.css */
.product-price {
    font-size: 2.5rem;
    color: #706F70;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: left;
}

.product-per-kit-price {
    font-size: 1.2rem;
    color: #706F70;
    margin-top: 0;
    font-weight: normal;
    text-align: left;
}