.create-invoice-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.primary-button {
    font-size: 1.2em;
    padding: 10px 20px;
    background-color: #2F80ED;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.primary-button:hover {
    background-color: #1e5bbf;
}
