/* CSS (SpeedSwabsHero.css) */
.speed-swabs-hero {
    padding: min(15vh, 250px) 5%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    overflow: hidden;
}

.speed-swabs-hero-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
}

.hero-text {
    text-align: left;
    max-width: 600px;
    margin-right: 20px;
}

.hero-heading {
    font-size: clamp(2rem, 5vw, 3rem); /* Fluid font size */
    font-weight: bold;
    margin: 0;
    line-height: 1.2;
    color: #706F70;
}

.hero-heading-highlight {
    color: #007bff;
}

.speed-swabs-hero-subheader {
    margin-top: 20px;
}

.hero-description {
    font-size: clamp(1rem, 2.5vw, 1.5rem); /* Fluid font size */
    line-height: 1.5;
    max-width: 60%;
}

/* Add a new container for the buttons */
.button-container {
    display: flex;
    gap: 10px; /* Space between the buttons */
    margin-top: 30px;
}

/* Ensure both buttons have the same size */
.button-container button {
    flex: 1;
    max-width: 250px; /* Set a max width if needed */
    padding: 15px 20px; /* Match button padding */
    font-size: 1rem; /* Ensure both buttons have the same font size */
}

@media (max-width: 768px) {
    /* Stack the buttons vertically on smaller screens */
    .button-container {
        flex-direction: column;
        align-items: stretch;
    }

    .button-container button {
        width: 100%;
    }
}

/* Responsive Styles */
@media (max-width: 1360px) {
    .speed-swabs-hero-content {
        flex-direction: column;
        align-items: flex-start;
    }

    .hero-text {
        text-align: left;
        margin-right: 0;
        max-width: 100%;
    }
}

@media (max-width: 1200px) {
    .speed-swabs-hero {
        padding: min(10vh, 200px) 10%;
    }

    .hero-text {
        margin-left: 0;
        margin-top: 15%;
    }
}

@media (max-width: 992px) {
    .hero-heading {
        font-size: clamp(1.75rem, 5vw, 2.25rem); /* Fluid font size */
    }

    .hero-description {
        font-size: clamp(0.9rem, 2.5vw, 1.2rem); /* Fluid font size */
    }

    .speed-swabs-hero {
        padding: min(8vh, 150px) 5%;
    }
    .hero-text {
        margin-top: 15%;
    }
}

@media (max-width: 768px) {
    .hero-heading {
        font-size: clamp(1.5rem, 5vw, 2rem); /* Fluid font size */
    }

    .hero-description {
        font-size: clamp(0.8rem, 2.5vw, 1.1rem); /* Fluid font size */
    }

    .speed-swabs-hero {
        align-items: flex-start;
        text-align: left;
        padding: min(7vh, 100px) 5%;
    }

    .hero-text {
        margin-top: 15%;
        max-width: 100%;
    }
}

@media (max-width: 576px) {
    .hero-text {
        margin-top: 10%;
        max-width: 100%;
    }
    .hero-heading {
        font-size: clamp(1.3rem, 5vw, 1.8rem); /* Fluid font size */
    }

    .hero-description {
        font-size: clamp(0.7rem, 2.5vw, 1rem); /* Fluid font size */
    }

    .speed-swabs-hero {
        padding: min(6vh, 100px) 5%;
    }
}

@media (max-width: 400px) {
    .hero-heading {
        font-size: clamp(1.2rem, 5vw, 1.5rem); /* Fluid font size */
    }

    .hero-description {
        font-size: clamp(0.7rem, 2.5vw, 0.9rem); /* Fluid font size */
    }

    .speed-swabs-hero {
        padding: min(5vh, 120px) 5%;
    }
}
