.notification-item {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    padding: 15px;
    margin-bottom: 10px;
}

.notification-content {
    margin-bottom: 15px;
}

.notification-actions {
    display: flex;
    justify-content: space-between;
}






/* new css */
.containerDropDown {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    max-width: 260px;
    border-radius: 17px;
    position: absolute;
    right: 0;
    top: auto;
    padding: 20px;
}

* {
    box-sizing: border-box;
}

.greenButton {
    background: #98d6bd;
    border: 2px solid #98d6bd;
    color: #fff;
    text-transform: capitalize;
}

.twoButonsWrappers {
    display: flex;
    justify-content: space-between;
    background: #fff;
}

.twoButonsWrappers button {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 12px;
}

.blueButton {
    background: #95afd1;
    border: 2px solid #95afd1;
}

.optionsDropDown {
    background: #6f7170;
    margin-bottom: 20px;
    border-radius: 10px;
}

.optionsDropDown h4 {
    box-shadow: rgb(0, 0, 0, 0.2) 0px 0px 8px 1px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    padding: 10px;
    text-align: center;
    background: #fff;
    font-size: 12px;
    text-transform: uppercase;
    margin: 0;
}

.optionsDropDown p {
    margin: 0;
    font-size: 14px;
    line-height: normal;
    padding: 10px 20px;
    text-align: center;
    color: #fff;
}

.optionsDropDown:last-child {
    margin: 0;
}

.optionsDropDown ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    list-style: none;
    font-size: 14px;
    background: #fff;
    box-shadow: rgb(0, 0, 0, 0.2) 0px 0px 8px 1px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    margin: 0;
    padding: 10px 7px;
}

.optionsDropDown p a {
    color: #fff;
    text-transform: capitalize;
}