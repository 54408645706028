.mro-page-header-row {
    display: grid;
    grid-template-columns: 115px 225px 85px 85px 85px 115px 85px 85px 85px; /* Explicitly set column sizes */
    gap: 10px;
    padding: 10px;
    align-items: center;
    overflow: visible;
    z-index: 50;
}

.mro-page-header-cell {
    flex: 1;
    text-align: center;
    font-weight: bold;
    padding: 10px;
}
