.shipment-rates-container {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.shipment-rates-list {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    padding: 20px;
    background-color: #4F4F4F;
    border-radius: 8px;
    margin-bottom: 5vh;
}

.shipment-rate-item {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 15vh;
}

.shipment-rate-item.selected {
    background-color: #7f7f7f;
    color: white;
}

.total-shipping-charge {
    font-weight: bold;
    font-size: 20px;
}

.shipment-rate-item:hover {
    background-color: #2F80ED;
    transition: background-color 0.3s ease;
}

.confirmation-modal-shipment-labels {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal-content-shipment-labels {
    background: white;
    padding: 20px;
    width: 400px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.modal-buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
}
