/* Container for the tabs (buttons) */
.shopify-tabs {
    display: flex;            /* Use Flexbox */
    justify-content: center;   /* Center the buttons horizontally */
    gap: 20px;                 /* Add spacing between the buttons */
    margin-bottom: 20px;       /* Space below the buttons */
}

/* Style for each button */
.shopify-tabs button,
.shopify-tabs .PrimaryButton {
    padding: 10px 20px;        /* Padding for buttons */
    font-size: 16px;           /* Font size for readability */
    cursor: pointer;           /* Pointer cursor on hover */
    border: none;              /* Remove default borders */
    background-color: #007bff; /* Default button background color */
    color: white;              /* Text color */
    border-radius: 5px;        /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}

/* Hover effect for buttons */
.shopify-tabs button:hover,
.shopify-tabs .PrimaryButton:hover {
    background-color: #0056b3; /* Darker background on hover */
}

/* Content area below the tabs */
.shopify-content {
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
}
