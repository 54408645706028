
.speed-swabs-nav-bubble-container {
    position: absolute;
    top: 10%; /* Place it 0.5 inches below the header */
    left: 50%; /* Center it horizontally */
    transform: translateX(-50%);
    z-index: 10;
    transition: all 0.3s ease;
}

.speed-swabs-nav-bubble-container.sticky {
    position: fixed; /* Stick it to the top when scrolling */
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    background-color: rgba(255, 255, 255, 0.9); /* Slightly opaque to match the header */
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow when sticky */
    border-radius: 0;
}
