/* CookieConsentBanner.css */
.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #706F70;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
}

.cookie-consent-text {
    font-size: 1rem;
    text-align: left;
    padding-left: 60px;
    max-width: 95%;
    color: #ffffff;
}

.cookie-consent-button {
    color: #ffffff;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
}

.cookie-consent-button-container {
    width: 20%;
}

.cookie-consent-button:hover {
    background-color: #2F80ED;
}


.cookie-consent-arrow {
    width: 7%;
}

@media (max-width: 768px) {
    .cookie-consent-banner {
        flex-direction: column;
        align-items: flex-start;
    }

    .cookie-consent-text {
        margin-bottom: 10px;
        max-width: 100%;
    }

    .cookie-consent-button {
        width: 20%;
        text-align: center;
        margin-left: 100px;
    }
}