/*freddy-react/src/components/autobot/client_selector/ClientSelector.css*/

/* ClientSelector.css */

.labb-clients-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ECEDED;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.labb-clients-search-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 350px;
}

.search-input {
    flex: 1;
    margin: 0.5rem 0;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    max-width: calc(95% - 0.5rem); /* Limit input width to 50% minus margin */
}

/* You might want to adjust the style of the dropdown menu further if needed */
.search-input .react-select__menu {
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.search-input .react-select__control {
    border-radius: 4px;
    border: 1px solid #e1e1e1;
}

.search-input .react-select__placeholder {
    color: #888;
}
