/* Filter container */
.filter-container {
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0;
}

/* Search input styles */
.filter-input {
    width: 100%;
    max-width: 400px;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s;
}

.filter-input:focus {
    outline: none;
    border-color: #007bff;
}

/* Adjust spacing for mobile */
@media (max-width: 768px) {
    .filter-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .filter-input {
        width: 100%;
    }
}
