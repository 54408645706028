.panel-details-model-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.panel-details-model-close-container{
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
}

.panel-details-model-container {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 1400px;
    max-height: 90vh;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.panel-details-model-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    cursor: pointer;
}

.panel-details-model-close-button img {
    width: 24px;
    height: 24px;
}

.panel-details-model-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.panel-details-model-service-description {
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
}

.panel-details-model-panel-code {
    font-size: 1em;
    color: #fff;
    font-weight: bold;
    background: #39B54A;
    padding: 5px 10px;
    border-radius: 5px;
}

.panel-details-model-masonry-grid {
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}

.panel-details-model-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}

.panel-details-model-masonry-grid_column > div {
    background: white;
    margin-bottom: 30px;
}

.panel-details-model-component-tiles {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    transition: background-color 0.3s ease;
}

.panel-details-model-component-tiles:hover {
    background: #f1f3f5;
}

.panel-details-model-item-tiles {
    flex: 1;
    margin-left: 10px;
}

.panel-details-model-item-details {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 5px 0;
    background-color: #145890;
    border-radius: 4px;
    padding: 5px 10px;
    color: #ffffff;
}

.panel-details-model-item-details .panel-details-model-item-tiles {
    color: #fff;
}
