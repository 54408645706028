.notification-list-container {
    max-width: 280px; /* Slightly less than modal width */
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
}

.notification-item:not(:last-child) {
    margin-bottom: 10px;
}
