
/* ClientPricingModel.css */
.pricing-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.pricing-modal {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.pricing-modal-header {
    display: flex;
    justify-content: flex-end;
}

.pricing-modal-content {
    overflow-y: auto;
    max-height: 500px;
}

.pricing-detail {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}
