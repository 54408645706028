/* Adapted styles from Organization */

.donor {
    width: 20%;
    max-width: 450px;
    min-width: 200px;
    height: fit-content;
    border: 2px solid #58585a;
    position: relative;
    background-color: #eceded;
    border-radius: 10px;
    margin-top: 1%;
    margin-left: 2%;
    font-family: Montserrat, sans-serif;
    color: #58585a;
}

.donor_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 5px;
    margin: 0;
    border-radius: 6px 6px 0 0;
    background-color: #ffffff;
    font-size: 12px;
    height: fit-content;
    border-bottom: 1px solid #58585a;
}

.donor_header_text {
    text-align: center;
    padding: 5px;
    margin: 0;
    border-radius: 6px;
    background-color: #ffffff;
    font-size: 15px;
}

.dark_grey_strip {
    width: 100%;
    background-color: #58585a;
    height: 6px;
}

.donor_info_section p {
    font-size: 1rem;
    color: #555;
    margin: 0;
}

.donor-searchable-dropdown {
    width: 80%;
    padding: 8px;
    margin: 10px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    font-size: 1rem;
    color: #555;
    background-color: #ffffff;
    appearance: none;
    cursor: pointer;
}
.donor_dropdown_text {
    width: 100%;
    padding: 8px;
    margin: 10px;
    font-size: 1rem;
    color: #555;
    appearance: none;
}


.dropdown-content {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    width: 80%;
    border: 1px solid #58585a;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto; /* add scrolling */
    -ms-overflow-style: none;  /* Internet Explorer 10+ remove scroll bar */
    scrollbar-width: none;  /* Firefox remove scroll bar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
    border-radius: 5px;
    transition: all 0.3s ease;
}

.donor_dropdown_row {
    width: 100%;
    padding: 4px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    font-size: 1rem;
    color: #555;
    background-color: #ffffff;
    cursor: pointer;
}

.dropdown-content div {
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.dropdown-content div:hover {
    background-color: #f5f5f5;
}

.dropdown-content::-webkit-scrollbar {
    width: 8px;
    display: none;  /* Safari and Chrome remove scroll bar*/
}

.dropdown-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.dropdown-content::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.dropdown-content::-webkit-scrollbar-track {
    background: #f5f5f5;
}
