.product-details {
    width: 15%;
    max-width: 350px;
    min-width: 200px;
    height: fit-content;
    border: 2px solid #58585a;
    position: relative;
    background-color: #eceded;
    border-radius: 10px;
    margin-top: 1%;
    margin-left: 2%;
    font-family: Geomanist, sans-serif;
    color: #58585a;
}

.product-header {
    text-align: center;
    padding: 5px;
    margin: 0;
    border-radius: 6px 6px 0 0;
    background-color: #ffffff;
    font-size: 12px;
    height: fit-content;
}

.dark-grey-strip {
    width: 100%;
    background-color: #58585a;
    height: 6px;
}

.product-header-text {
    text-align: center;
    padding: 5px;
    margin: 0;
    border-radius: 6px;
    background-color: #ffffff;
    font-size: 12px;
}

.product-info {
    margin: 10px;
}

.product-info-text {
    text-align: right;
    margin: 0;
    font-size: 12px;
}
