.orders-table {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
    width: 100%;
    align-content: start;
}


@media (max-width: 768px) {
    .orders-table {
        /*apply masinry flag to grid template colums*/
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}
