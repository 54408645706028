/* Search Bar Container */
.organization-filter-container {
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0;
}

/* Search Input Styles */
.organization-filter-input {
    width: 100%;
    max-width: 400px;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s;
}

.organization-filter-input:focus {
    outline: none;
    border-color: #007bff;
}

/* No Data Message */
.no-data-message {
    text-align: center;
    font-size: 16px;
    color: #666;
    margin-top: 20px;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
    .organization-filter-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .organization-filter-input {
        width: 100%;
    }
}
