.public-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F0F0F0;
    padding: 10px 20px;
}

.logo-link {
    display: flex;
    align-items: center;
}

.header-logo {
    height: 50px;
}