.speed-product-faq {
    width: 100%;
    padding: 20px;
    margin-top: 20px;
}

.speed-product-faq h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-align: center;
}

.speed-product-faq p {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 20px;
}

.faq-item {
    margin-bottom: 15px;
}

.faq-item button {
    display: block;
    width: 100%;
    background-color: #f0f0f0;
    border: none;
    text-align: left;
    padding: 15px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.faq-item button:hover {
    background-color: #e0e0e0;
}

.faq-item p {
    padding: 10px;
    background-color: #e8f0fe;
    border-left: 4px solid blue;
    margin: 0;
    display: block;
}
