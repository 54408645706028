.public-freddy-login__container {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}

.public-freddy-login__form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.public-freddy-login__form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
}

.public-freddy-login__form-group input::placeholder {
    color: #aaa;
}

.public-freddy-login__error-message {
    color: red;
    margin-bottom: 15px;
    text-align: center;
}