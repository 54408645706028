.action-tray-container {
    position: relative;
    display: inline-block;
}

.action-icons {
    display: flex;
    position: absolute;
    /*top: 0; !* Align with the top of the trigger *!*/
    right: 0; /* Align the right side of the tray with the right side of the container */
    transform: translateX(0); /* Remove the centering effect */
    max-width: 200px; /* Limit the maximum width of the tray */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff; /* Lighter background for better contrast */
    border-radius: 8px; /* Slightly more rounded corners */
    z-index: 1000;
    overflow: hidden; /* Ensure content doesn't spill out */
    white-space: nowrap; /* Prevent text from wrapping if any */
}

.icon-wrapper {
    margin: 0 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-wrapper:hover {
    background-color: #e0e0e0; /* Add a hover effect for better UX */
    border-radius: 4px; /* Match the icon button border radius */
}
