.client-fast-paste-select {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.paste-input {
    width: 100%;
    height: 100px;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    resize: none;
}
