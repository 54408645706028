/* Base Styles: Mobile-First */
.organizations-table-header-row,
.organization-table-row {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #ddd;
    padding: 8px 0;
    align-items: center;
}

.organizations-table-header-cell,
.organization-table-cell {
    flex: 1 1 100%; /* Cells take up full width by default */
    padding: 8px 10px;
    text-align: left;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #f3f3f3;
}

.organizations-table-header-row {
    background-color: #f7f9fc;
    color: #333;
    font-weight: bold;
    border-bottom: 2px solid #ddd;
}

.organization-table-row:hover {
    background-color: #f1f1f1;
}

/* Action Tray Cell */
.organization-action-tray-table-cell {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Scrollable Container for Overflowing Content */
.organization-table-scroll-container {
    max-height: 700px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
}

/* Larger Screens: Tablet and Up */
@media (min-width: 768px) {
    .organizations-table-header-row,
    .organization-table-row {
        display: grid;
        grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1.5fr 1fr 0.5fr 0.5fr 1fr;
        padding: 10px 0;
    }

    .organizations-table-header-cell,
    .organization-table-cell {
        flex: unset; /* Reset Flex to Grid Behavior */
        padding: 12px 15px;
        text-align: left;
        font-size: 14px;
        border: none;
    }
}

/* Desktop-Specific Enhancements */
@media (min-width: 1024px) {
    .organizations-table-header-row,
    .organization-table-row {
        padding: 12px 0;
    }

    .organization-table-scroll-container {
        max-height: none;
        overflow-y: visible;
    }
}

.missing-dot-account {
    background-color: #ffefef;
}
