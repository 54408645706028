
/* Fullscreen Modal Overlay */
.talkswabs-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it's overtop other content */
}

/* Modal Container */
.talkswabs-modal-container {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 800px;
    height: 70%; /* Reduced height to minimize whitespace */
    overflow-y: auto; /* Allows scrolling if content exceeds container height */
    position: relative; /* To keep the close button aligned */
}

/* Modal Header */
.talkswabs-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

/* Close Button */
.talkswabs-modal-close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

/* Form Group Styles */
.talkswabs-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

/* Success Message Styles */
.talkswabs-success {
    text-align: center;
    padding: 2rem;
}

.talkswabs-success-icon {
    margin-bottom: 1rem;
}

.talkswabs-success-heading {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #007bff;
}

.talkswabs-success-message {
    font-size: 1rem;
    margin-bottom: 2rem;
    color: #4a4a4a;
}

.talkswabs-button-group {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.talkswabs-primary-button {
    padding: 0.75rem 2rem;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.talkswabs-primary-button:hover {
    background-color: #0056b3;
}

.talkswabs-secondary-button {
    padding: 0.75rem 2rem;
    background-color: #f1f1f1;
    color: #007bff;
    border: 1px solid #007bff;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.talkswabs-secondary-button:hover {
    background-color: #e0e0e0;
    color: #0056b3;
}

/* Volume Selector Container */
.talkswabs-volume-selector {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; /* Space between volume buttons */
    justify-content: space-between;
}

/* Volume Button Styling */
.talkswabs-volume-option {
    padding: 0.75rem 1.5rem;
    border: 1px solid #dcdcdc;
    border-radius: 50px; /* Rounded corners for pill shape */
    background-color: #f1f1f1;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    flex: 1 1 calc(33% - 1rem); /* Ensures multiple buttons per row */
    text-align: center;
    font-size: 1rem;
}

/* Selected Volume Button */
.talkswabs-volume-option.selected {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

/* Volume Description */
.talkswabs-volume-description {
    font-size: 1.2rem;
    margin-top: 1rem;
    text-align: center;
}

.talkswabs-volume-description strong {
    font-size: 1.5rem; /* Emphasized numbers */
    font-weight: bold;
    color: #007bff;
}

/* Important Factors Container */
.talkswabs-important-factors {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; /* Space between options */
    justify-content: space-between;
}

/* Important Factor Button */
.talkswabs-important-option {
    padding: 0.75rem 1.5rem;
    border: 1px solid #dcdcdc;
    border-radius: 50px; /* Rounded corners for pill shape */
    background-color: #f1f1f1;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    flex: 1 1 calc(33% - 1rem); /* Ensures multiple buttons per row */
    text-align: center;
    font-size: 1rem;
}

/* Selected Important Factor Button */
.talkswabs-important-option.selected {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

/* Error Styling */
.error {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    display: block;
    text-align: left;
}

/* Button Group Margin Fix for Error Messages */
.talkswabs-button-group {
    margin-top: 0;
}
