.pricing-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.pricing-modal {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 600px;
    padding: 20px;
    position: relative;
}

.pricing-modal-header {
    display: flex;
    justify-content: flex-end;
}

.pricing-modal-content {
    margin-top: 20px;
}

.client-group {
    margin-bottom: 20px;
}

.client-group h3 {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #333;
}

.client-group ul {
    list-style: none;
    padding: 0;
}

.client-group li {
    background: #f9f9f9;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
