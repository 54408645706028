
/* SpeedSwabsOverview.css */
.speed-swabs-overview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #FFFFFF;
}

.speed-swabs-hero-graphic-container {
    position: fixed;
    right: 0;
    top: 30%;
    transform: translateY(-50%);
    z-index: 1;
    max-width: 600px;
}

.feature-section-hero-text-wrapper {
    width: 100%;
    padding: 60px 0;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;
}

.how-to-use-section-wrapper {
    width: 100%;
    margin-top: 40px;
    display: flex;
}


.do-not-overpay-section-wrapper {
    width: 100%;
    margin-top: 200px;
    margin-bottom: 200px;
}

@media (max-width: 768px) {
    .speed-swabs-hero-graphic-container {
        position: static;
        transform: none;
        max-width: 100%;
        margin: 0 auto;
    }

    .feature-section-hero-text {
        margin-left: 0;
        text-align: center;
    }

    .how-to-use-section-wrapper {
        padding: 10px;
    }

    .do-not-overpay-section-wrapper {
        padding: 10px;
    }
}