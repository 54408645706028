.shopify-cart-footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
}

.cart-total {
    flex-grow: 1;
}

.total-text {
    font-size: 14px;
    color: #333;
}

.total-amount {
    font-size: 24px;
    font-weight: bold;
}

.shopify-cart-footer-buttons {
    display: flex;
    gap: 50%;
}

.checkout-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 12px 20px;
}

.continue-shopping-button {
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 12px 20px;
}
