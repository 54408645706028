.customer-details-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f4;
    padding: 20px;
}

.customer-details-card {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
}

.customer-details-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333333;
    font-family: 'Geomanist', sans-serif;
}

.customer-details-email {
    font-size: 18px;
    color: #555555;
    font-family: 'Geomanist', sans-serif;
    word-wrap: break-word;
}
