/* Header.css */

.main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;  /* Giving a defined height for consistency */
    padding: 0px 16px;
    background-color: #f5f5f5;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.header-logo {
    width: 100px;
    height: 40px;
    object-fit: contain;
}

.nav-items {
    display: flex;
    gap: 16px;
}

.nav-item {
    display: flex;
    align-items: center;
}

.icons-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.icons-container:hover {
    transform: scale(1.1);
}

.icons-container img {
    width: 24px;  /* Given a standard size for all icons */
    height: 24px;
    object-fit: contain;
}

.navbar-container {
    display: flex;
    gap: 12px;  /* Space between different icons */
    align-items: center;
}

.nav-button {
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.nav-button:hover {
    background-color: #e4e4e4;
}

.nav-button-text {
    color: #333333;
    font-size: 16px;
    font-weight: bold;
}

.nav-button-logout {
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.nav-button-logout:hover {
    background-color: #e4e4e4;
}

.nav-button-logout-icon {
    width: 19px;
    height: 19px;
    stroke: #58585A;
}

.notificaitons-dashboard {
    z-index: 9999;
}

.fetch-mail-icon-wrapper {
    background-color: #000;
}




/* menu white background css */
.navbar-container .icons-container a.whiteActive {
    position: relative;
}

.navbar-container .icons-container a.whiteActive::after {
    content: "";
    display: block;
    width: 29px;
    height: 50px;
    background: #fff;
    position: absolute;
    top: -7px;
    left: -2px;
    z-index: 3;
    border-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.navbar-container .icons-container a.whiteActive img {
    position: relative;
    z-index: 4;
}