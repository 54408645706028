/* Container for industry buttons */
.industry-selector-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; /* Adds space between buttons */
    justify-content: space-between; /* Evenly space buttons */
}

/* Basic button styling */
.industry-selector-button {
    padding: 0.75rem 1.5rem;
    border: 1px solid #dcdcdc;
    border-radius: 50px; /* Rounded corners for pill-like style */
    background-color: #f1f1f1;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    flex: 1 1 calc(50% - 1rem); /* Ensures two buttons fit per row */
    text-align: center;
}

/* Selected state styling */
.industry-selector-button-selected {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

/* Hover and focus states */
.industry-selector-button:hover,
.industry-selector-button:focus {
    background-color: #e0e0e0;
    border-color: #007bff;
}
