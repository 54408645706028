/*freddy-react/src/components/specimens/Specimens.css*/

.specimens-container {
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #f6f6f6;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding-top: 0px;
}

.filter-search-container {
    margin-bottom: 20px;
}

.specimens-table-container {
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 15px;
    position: relative;
    z-index: 1;
}


/* Download csv button css */
.downloadCSV {
    margin-left: 10px;
}

.downloadCSV button {
    width: 120px;
    height: 36px;
    font-size: 14px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #e0e0e0;
    color: rgb(0 0 0 / 40%);
    font-weight: 300;
}
