
/* VariantSelector.css */
.panel-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 30px;
    flex-wrap: nowrap;
}

.panel-select {
    min-width: 600px;
    width: 40%;
    padding: 12px;
    font-size: 1rem;
    border-radius: 25px;
    border: 2px solid #706F70;
    background-color: #fff;
    appearance: none;
    background-position: right 15px center;
    background-repeat: no-repeat;
    background-size: 10px;
    margin-bottom: 0;
}

.panel-select-text {
    color: #706F70;
}

.add-to-cart-button {
    padding: 5px 30px;
    font-size: 1.2rem;
    background-color: #28A745;
    color: white;
    border-radius: 25px;
    border: none;
    white-space: nowrap;
    cursor: pointer;
    gap: 8px;
    transition: background-color 0.3s ease;
    height: auto;
    flex-shrink: 0;
    display: flex;
    align-items: center;
}